import { compact, values, uniq } from 'lodash';

import { t } from 'utils';

import { couponsStore } from '../../stores';

/**
 * @description
 * Generate errors based on form coupon values and usage_per_coupon col
 * if duplicated coupons > usage_per_coupon, generate errors,
 * { [KKJSD13]: 'Coupon already in use by another member...' }
 *
 */
function generateDuplicatedErrors(allFields: string[], formValues: AnyObject) {
  const _usedCodes = compact(values<string | nil>(formValues));
  const codeUses = uniq(_usedCodes).reduce((codeUses, code) => {
    codeUses[code] = _usedCodes.filter((usedCode) => usedCode === code).length;
    return codeUses;
  }, {});

  return allFields.reduce((errors, fieldId) => {
    const code = formValues[fieldId];
    const couponUses = codeUses[code];
    const availableCouponQuantity = couponsStore.find(code)?.uses_per_coupon || Infinity;

    if (couponUses > availableCouponQuantity) {
      errors[fieldId] = [t.staticAsString('validation.couponQuantity')];
      codeUses[code]--;
    }

    return errors;
  }, {});
}

export { generateDuplicatedErrors };
