import axios from 'axios';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { API_URL, API_SMART_LINK } from 'src/constants';

import { action, fireToast, request, t } from 'utils';

class SmartLink {
  @request({ action: shortid() })
  private checkSmartLinkRequest(token: string): Promise<any> {
    const url = generatePath(API_URL + API_SMART_LINK, { token });
    return axios.post(url);
  }

  @action({ action: shortid() })
  async checkSmartLink(token: string) {
    const [isOk, response] = await this.checkSmartLinkRequest(token);
    if (isOk) {
      return response.data.data;
    }
    const { message } = response.data;
    if (message) {
      if (message === "Magic link can't be used today.") {
        fireToast(t.staticAsString('smartLinks.cantBeUsedToday'), 'warning');
      }
      if (message === 'Magic link was used max times') {
        fireToast(t.staticAsString('smartLinks.wasUsedMaxTimes'), 'warning');
      }
      return;
    }
    fireToast(t.staticAsString('smartLinks.smartLinkNotValid'), 'warning');
  }
}

export const smartLinkService = new SmartLink();
