import { changeDistance } from './changeDistance';
import { cleanForm, cleanErrors, cleanDistanceSelector } from './clean';
import { initForm } from './initForm';
import { initFormDataAfterRedirect } from './initFormDataAfterRedirect';
import { initFormDataFromDistanceRegistration } from './initFormDataFromDistanceRegistration';
import { initFormDataFromProfile } from './initFormDataFromProfile';
import { initUrlBasedData } from './initUrlBasedData';
import { logoutIfConfirmation } from './logoutIfConfirmation';

/**
 * @description
 * actions about mutating data here
 */
const dataActions = {
  initUrlBasedData,
  initFormDataAfterRedirect,

  /**
   * @description
   * init registration leader form from the logged user data (sessionStore)
   */
  initForm,

  cleanForm,
  cleanErrors,

  /**
   * @description
   * clean distance form selector
   */
  cleanDistanceSelector,
  /**
   * @description
   * init registration leader form from the logged user data (sessionStore)
   * autofileld
   */
  initFormDataFromProfile,

  /**
   * @description
   * change distance for the distance selector
   */
  changeDistance,

  logoutIfConfirmation,

  /**
   * @description
   * init registration leader form from the distance registration user data (if user logged)
   * autofilled
   */
  initFormDataFromDistanceRegistration,
};

export { dataActions };
