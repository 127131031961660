import { keys, first } from 'lodash';
import { isEmpty } from 'lodash';
import { toastUtils } from 'modules/registration/utils/toasts';

import { toastService } from 'utils';

import { REGISTRATION_BE_ERROR_KEY_PATTERNS } from '../../constants';

/**
 * @description
 * Catch errors that cannot be applied to the form and should be displayed in the toast
 */
function catchRegistrationToastErrors(message: string | nil, rawErrors: RawError | nil) {
  if (isEmpty(rawErrors)) {
    message && toastService.error(message);
    return;
  }

  const errorKeys = keys(rawErrors);

  const matchers = REGISTRATION_BE_ERROR_KEY_PATTERNS.toastMatchers();

  const isToast = errorKeys.find((key) => {
    const error = first(rawErrors![key]);

    if (matchers.find((matcher) => matcher.test(key)) && error) {
      toastService.error(error);
      return true;
    }

    return false;
  });

  if (!isToast) {
    toastUtils.generalValidation();
  }
}

export { catchRegistrationToastErrors };
