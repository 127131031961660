import { parseUrlParams } from '../../utils';

import { confirmationService } from '../../services';

import { confirmationStore } from '../../stores';

/**
 * @description
 *
 * Load racer by token to init confirmation
 *
 * init user in store if needed
 * logout user if token is valid
 */
async function loadConfirmation() {
  const { inviteToken } = parseUrlParams();

  if (!inviteToken) {
    return;
  }

  const response = await confirmationService.loadConfirmation(inviteToken);

  if (response) {
    confirmationStore.setTokenData(inviteToken, response);
  } else {
    confirmationStore.setInvalidInviteToken();
  }
}

export { loadConfirmation };
