import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { API_URL, API_DELETE_CANCELED_PAYMENT, DELETE_CANCELED_PAYMENTS, PAYMENT_STATUS, PATCH_PAYMENT } from 'src/constants';

import { request, action } from 'utils';

import { Races as RacesService } from 'services';

import { afterPaymentStore, AfterPayment, progressStore } from 'stores';

import { snackActions } from '../actions';

const MAIN_LOOP_DELAY = 4000;

class AfterPaymentHandler {
  raceId?: number;
  distanceId?: number;
  service?: RacesService;
  store: AfterPayment;
  isLoopActive: boolean;
  pendingPayments: {
    [K in string]: string;
  };
  timeout: number | null = null;

  constructor() {
    this.pendingPayments = {};
    this.isLoopActive = true;

    this.store = afterPaymentStore;
  }

  async mainLoop() {
    if (!this.isLoopActive) {
      return;
    }

    const payment = await this.loadData();

    if (!this.isLoopActive) {
      return;
    }

    this.store.changePaymentStatus(payment.state);

    this.timeout = setTimeout(this.mainLoop.bind(this), MAIN_LOOP_DELAY) as unknown as number;
  }

  async loadData() {
    const { raceId, distanceId } = this;
    const response = await this.service?._loadResource(`${raceId}`);

    const distance = response.data.data.distances.find((distance: AnyObject) => +distance.id === Number(distanceId));
    if (distance.already_registered) {
      return { state: PAYMENT_STATUS.ready };
    } else {
      return distance.registrations[distance.registrations.length - 1];
    }
  }

  clear() {
    this.store.cleanPayment();
    this.timeout && clearTimeout(this.timeout);
    this.isLoopActive = false;
  }

  @request({ action: DELETE_CANCELED_PAYMENTS })
  async deleteCanceledPaymentsRequest({ token }: { token: string }): Promise<any> {
    const path = generatePath(`${API_URL}${API_DELETE_CANCELED_PAYMENT}`, { token });
    return axios.delete(path);
  }

  @action({ action: DELETE_CANCELED_PAYMENTS })
  async deleteCanceledPayments({ token, distanceId }: { token: string; distanceId: number }) {
    // NOTE:
    // DO NOT send delete payment
    // IF patch payment is in progress
    // IF patch payment already completed
    if (progressStore.isLoading(PATCH_PAYMENT)) {
      return;
    }
    if (this.store.isPatchComplete) {
      return;
    }

    const [status, response] = await this.deleteCanceledPaymentsRequest({ token });

    // Fail to cancel payment
    if (!status && response.status === 400) {
      snackActions.payment.cannotCancelSnack.show();
      return false;
    }

    // Canceled payment
    return status;
  }
}

export { AfterPaymentHandler };
