import { snackStore } from 'stores';

import { SNACK_SCOPES } from '../../../constants';

import { awaitingSnack } from './awaiting';
import { cancelSnack } from './cancel';
import { cannotCancelSnack } from './cannotCancel';
import { pendingSnack } from './pending';
import { successSnack } from './success';

function clean() {
  snackStore.removeAll((snack) => snack.options?.scope === SNACK_SCOPES.payment);
}

export const payment = {
  successSnack,
  pendingSnack,
  awaitingSnack,
  cancelSnack,
  cannotCancelSnack,
  clean,
};
