import { reaction } from 'mobx';

import { ROUTER } from 'src/constants';

import { history } from 'utils';

import { racesService } from 'services';

import { localeStore } from 'stores';

import { parseDistanceId } from '../utils/parseDistanceId';
import { parseRaceId } from '../utils/parseRaceId';

import { loadRace } from '../actions';

import { disposers } from '../stores';

import { loadDistance } from '../../registration/actions/loader/loadDistance';

const initialize = async () => {
  const raceId = parseRaceId();
  if (raceId) {
    const response = await racesService.loadResource(raceId);
    if (response.status === 404) history.replace(ROUTER.NOT_FOUND_PAGE);
    else loadRace();
  }

  const distanceId = parseDistanceId();
  if (distanceId) loadDistance();
};

export const observeLanguageChange = () => {
  const disposer = reaction(
    () => localeStore.value,
    () => {
      initialize();
    },
  );
  disposers.register(disposer);
};
