import { parseUrlParams } from '../../utils/parseUrlParams';

import { wavesService } from '../../services';

import { wavesStore } from '../../stores';

/**
 * @description
 * Load waves list for specific distance
 */
async function loadWaves() {
  const { distanceId } = parseUrlParams();

  if (!distanceId) {
    return;
  }

  const waves = await wavesService.load(distanceId);
  wavesStore.setValue(waves);
}

export { loadWaves };
