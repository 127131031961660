import { keys } from 'lodash';

import { SIGNUP } from 'src/constants';

import { errorsStore } from 'stores';

import { fieldIdUtils } from '../../utils';

import { _addErrors } from './_addErrors';

/**
 * @description
 * Remap and add profile registration errors
 * email,password, password_confirmation
 */
function catchProfileRegistrationErrors() {
  const errors = errorsStore.erroribus[SIGNUP];

  if (!errors) {
    return;
  }

  const errorKeys = keys(errors);

  const mappedErrors = errorKeys.reduce((processedErrors, errorKey) => {
    const newErrorKey = `${fieldIdUtils.registrationLeader()}.${errorKey}`;

    processedErrors[newErrorKey] = errors[errorKey];

    return processedErrors;
  }, {});

  _addErrors(mappedErrors);
}

export { catchProfileRegistrationErrors };
