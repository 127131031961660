import { form, errorsStore, authorizedRace, overlayStore } from 'stores';
import registrationStatus from 'stores/registrationStatus';

import { DISTANCES_FORM, REGISTRATION_FORM } from '../constants';

import { preloaders } from '../utils';

import {
  disposers,
  couponsStore,
  stepperStore,
  paymentTrackerStore,
  disabledCustomFieldsStore,
  distanceStore,
  raceStore,
  raceDistancesListStore,
  signInModalStore,
  currentPaymentStore,
  isPaymentFrameOpened,
  isOfflineStore,
  confirmationStore,
  wavesStore,
  emailExistsStore,
  isPaymentSuccessfullyCompleted,
  transferStore,
  disabledRegistrationFieldsStore,
  smartLinkStore,
  serviceFeeStore,
  distanceProductStore,
  giftcardStore,
  isPayOnSiteSelectedStore,
  isPayOnLineSelectedStore,
} from '../stores';

import {
  observeCouponErrors,
  observeLoadedDistance,
  observeRegistrationLeaderEmail,
  observeLoggedState,
  observeDistanceChangeForLoad,
  observePendingPayments,
  observeCurrentPayment,
  observeConditionsForPermanentSnacks,
  observeInternetConnection,
  observeInvalidRequestToken,
  stripeRedirectObserver,
  observeRegistrationClosedModal,
  observeSmartLink,
  observeGiftcardErrors,
} from '../reactions';
import { mainConditions } from '../selectors/mainConditions';
import { checkForNotFoundPage } from './checkForNotFoundPage';
import { checkIfInvalidTransfer } from './checkIfInvalidTransfer';
import { checkIfSmartLinkValid } from './checkIfSmartLinkValid';
import { checkStripeRedirect } from './checkStripeRedirect';
import { controlsActions } from './controls';
import { dataActions } from './data';
import { loaderActions } from './loader';
import { loadingLoops } from './loadingLoops';
import { snackActions } from './snacks';

async function mount() {
  paymentTrackerStore.clean();
  isPaymentSuccessfullyCompleted.off();
  observeConditionsForPermanentSnacks();
  observeInternetConnection();
  observePendingPayments();
  observeRegistrationLeaderEmail();
  observeLoggedState();
  observeCurrentPayment();
  observeInvalidRequestToken();
  observeRegistrationClosedModal();
  observeSmartLink();
  checkIfSmartLinkValid();

  form.registerForm(REGISTRATION_FORM);
  form.registerForm(DISTANCES_FORM);

  preloaders.initializeBaseData.enable();
  stepperStore.init();

  await loaderActions.loadBaseData();

  // if (checkForRequestToken()) return;
  if (checkIfInvalidTransfer()) return;

  dataActions.logoutIfConfirmation();

  loadingLoops.paymentsByDistance();
  loadingLoops.paymentByToken();

  if (checkForNotFoundPage()) return;

  controlsActions.authorizeRace();

  /**
   * Trigger cleansing and init of the new distance
   * when new distance was loaded
   */
  observeLoadedDistance();

  /**
   * Trigger distance loading
   * when distance was changed in the selector or in the history
   */
  observeDistanceChangeForLoad();
  observeCouponErrors();
  observeGiftcardErrors();

  dataActions.initUrlBasedData();

  if (mainConditions.isStripeRedirect()) {
    preloaders.loadRegistrationStatus.enable();
    stripeRedirectObserver();
  }

  if (mainConditions.isDistanceSelectorMode()) {
    preloaders.initializeBaseData.disable();
  }

  await checkStripeRedirect();
}

function unmount() {
  disposers.disposeAll();

  preloaders.initializeBaseData.disable();
  preloaders.initializeDistance.disable();
  preloaders.submitInProgress.disable();
  preloaders.loadRegistrationStatus.disable();

  snackActions.payment.clean();
  snackActions.blockedRegistration.clean();
  snackActions.offlineSnack.clean();

  paymentTrackerStore.clean();
  disabledCustomFieldsStore.clean();
  disabledRegistrationFieldsStore.clean();
  couponsStore.clear();
  giftcardStore.clear();
  distanceStore.clear();
  raceStore.clear();
  raceDistancesListStore.clear();
  signInModalStore.off();
  overlayStore.hide();
  stepperStore.clear();
  currentPaymentStore.clear();
  serviceFeeStore.clear();
  isPaymentFrameOpened.off();
  isOfflineStore.off();
  confirmationStore.clean();
  transferStore.clean();
  emailExistsStore.off();

  wavesStore.clear();
  smartLinkStore.clear();

  form.clean(REGISTRATION_FORM);
  errorsStore.clear(REGISTRATION_FORM);
  distanceProductStore.clear();

  dataActions.cleanForm();
  dataActions.cleanErrors();
  dataActions.cleanDistanceSelector();

  authorizedRace.clearActive();

  registrationStatus.isAttemptedPayment = false;
  registrationStatus.isRegisteredSuccessfully = false;

  isPayOnLineSelectedStore.off();
  isPayOnSiteSelectedStore.off();
}

export { mount, unmount };
