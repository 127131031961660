import { reaction } from 'mobx';

import { controlsActions } from '../actions';

import { disposers } from '../stores';

import { giftcardErrorReactionTrigger } from '../selectors/giftcards/giftcardErrorReactionTrigger';

/**
 * @description
 * Observe is there new errors added for giftcards
 * Error types:
 * 1. Is it is invalid giftcard code
 * 2. Or there is not enough uses for giftcards per amount of group users
 * (users with the same giftcard) > giftcard.uses_per_giftcard
 *
 * Anyway
 *
 * If new errors has been added,
 * triggered reaction will ADD THOSE ERRORS INTO errorsStore
 * And those will be displayed in the form fields
 */
function observeGiftcardErrors() {
  const disposer = reaction(giftcardErrorReactionTrigger, () => {
    controlsActions.addGiftcardErrors();
  });

  disposers.register(disposer);
}

export { observeGiftcardErrors };
