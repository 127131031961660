import { parseUrlParams } from '../../utils';

import { changeDistance } from './changeDistance';

function initUrlBasedData() {
  const { distanceId } = parseUrlParams();
  if (distanceId) {
    changeDistance(distanceId);
  }
}

export { initUrlBasedData };
