import axios from 'axios';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { API_DISTANCE_V2, API_DISTANCES_SQUADS, API_URL, API_V2_URL } from 'src/constants';

import { request, action } from 'utils';

class SquadService {
  @request({ action: shortid() })
  private requestLoad(distanceId: number, params: any): any {
    const url = generatePath(API_URL + API_DISTANCES_SQUADS, { id: distanceId });
    return axios.get(url, { params });
  }

  @request({ action: shortid() })
  private requestStore(distanceId: number, name: string): any {
    const url = generatePath(API_URL + API_DISTANCES_SQUADS, { id: distanceId });
    return axios.post(url, { name });
  }

  @action({ action: shortid() })
  async load(distanceId: number): Promise<null | DistanceType> {
    const queryParams = {
      page: 1,
      orderBy: 'name',
      sortedBy: 'asc',
      limit: 2000,
    };

    const [isOk, response] = await this.requestLoad(distanceId, queryParams);

    if (isOk) {
      return response.data.data;
    }

    return null;
  }

  @action({ action: shortid() })
  async store(distanceId: number, name: string): Promise<null | DistanceType> {
    const [isOk, response] = await this.requestStore(distanceId, name);

    if (isOk) {
      return response.data.data;
    }

    return null;
  }
}

export { SquadService };
