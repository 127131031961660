import { CountryModel } from 'models';

import { countriesStore } from 'stores';

function getCountryByLocation(location?: LocationType): CountryModel | undefined {
  if (!location) {
    return undefined;
  }

  return countriesStore.modelValues.find((country) => country.value.alpha_2_code === location.country_code);
}

function getCountryIdByName(name: string): CountryModel | undefined {
  if (!name) {
    return undefined;
  }

  return countriesStore.modelValues.find((country) => country.name === name);
}

export { getCountryByLocation, getCountryIdByName };
