import { snackStore } from 'stores';

function show(
  options: {
    freeDistance: boolean;
  } = { freeDistance: false },
) {
  const key = options.freeDistance && '_no_profile';

  const snackParams = {
    i18key: 'registration.payment.success' + (key || ''),
  };

  const snackId = snackStore.success(snackParams);
  snackStore.removeWithTimeout(snackId, 8000);
}

const successSnack = {
  show,
};

export { successSnack };
