import { t } from 'utils';

export const squadValidationRules = (): any => {
  const presence = {
    allowEmpty: false,
    message: t.staticAsString('validation.required' as TranslationLockedKeys),
  };

  return {
    squad: {
      presence,
      length: {
        minimum: 3,
        maximum: 255,
      },
    },
  };
};
