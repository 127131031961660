import { reaction } from 'mobx';

import { history } from 'utils';

import { navigateUtil } from '../utils';
import { parseUrlParams } from '../utils';

import { dataActions } from '../actions';
import { loaderActions } from '../actions/loader';

import { disposers } from '../stores';

import { formDataSelectors } from '../selectors/formDataSelectors';

/**
 * @description
 *
 * Load distance and push to history when it's changed in the form
 *
 * Change distance in the form when it's changed in history
 *
 */
function observeDistanceChangeForLoad() {
  let doNotTriggerHistoryPush: boolean = true;
  let replaceHistory = false;

  if (!parseUrlParams().distanceId) {
    /**
     * a case when link looks like this: /:locale/races/:id/registration
     * user did not chose distance yet
     */
    replaceHistory = true;
  }

  (function () {
    let prevDistanceId: number | nil = parseUrlParams().distanceId;

    const disposer = history.listen(() => {
      const { distanceId } = parseUrlParams();

      if (distanceId === prevDistanceId) {
        return;
      }

      prevDistanceId = distanceId;

      doNotTriggerHistoryPush = true;

      dataActions.changeDistance(distanceId);
    });
    disposers.register(disposer);
  })();

  (function () {
    const disposer = reaction(
      formDataSelectors.fetchCurrentDistanceId,
      async (id) => {
        if (id) {
          if (!doNotTriggerHistoryPush) {
            navigateUtil.changeDistance(id!);
          } else if (replaceHistory) {
            navigateUtil.changeDistance(id!, true);
          }

          doNotTriggerHistoryPush = false;
          replaceHistory = false;
          await loaderActions.loadDistance();
          await loaderActions.loadWaves();
          await loaderActions.loadSquads();
        }
      },
      { equals: (p, n) => p === n },
    );

    disposers.register(disposer);
  })();
}

export { observeDistanceChangeForLoad };
