import axios from 'axios';
import shortid from 'shortid';

import { API_URL, API_CONFIRM_TRANSFERRING, API_TRANSFERRED_REGISTRATION } from 'src/constants';

import { request, action, encodeQuery } from 'utils';

import { Request, Response } from '../types';

class TransferringService {
  @request({ action: shortid() })
  _submitRequest(raceId: number, data: Request.TransferRegistration.Plain): any {
    const url = encodeQuery.generatePath(API_URL + API_CONFIRM_TRANSFERRING, { raceId });
    return axios.post(url, data);
  }

  @request({ action: shortid() })
  private _loadTransferredRegistrationRequest(token: string): any {
    return axios.get(`${API_URL}${API_TRANSFERRED_REGISTRATION}/${token}`);
  }

  @action({ action: shortid() })
  /**
   * @description
   * submit for the confirmation with token
   */
  async submitTransferConfirmation(
    raceId: number,
    data: Request.TransferRegistration.Plain,
  ): Promise<{
    data: null | Response.Registration.SingleGroup;
    isOk: boolean;
    errors: RawError;
    message: string | null;
  }> {
    const [isOk, response] = await this._submitRequest(raceId, data);

    return {
      isOk,
      data: response.data,
      errors: response.data.errors,
      message: response.data.message,
    };
  }

  @action({ action: shortid() })
  /**
   * @description
   * load racer confirmation data by token
   */
  async loadTransferredRegistration(token: string): Promise<Response.Transferring.LoadedTransferredRegistration | number> {
    const [isOk, response] = await this._loadTransferredRegistrationRequest(token);

    if (isOk) {
      return response.data.data;
    }

    return response.status;
  }
}

export { TransferringService };
