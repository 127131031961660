import { chain, merge } from 'lodash';
import { transaction } from 'mobx';

import { form } from 'stores';

import { REGISTRATION_FORM } from '../../constants';

import { fieldIdUtils, mapperUtils } from '../../utils';

import { confirmationStore, disabledCustomFieldsStore } from '../../stores';

import { fieldConditions } from '../../selectors/fieldConditions';
import { mainConditions } from '../../selectors/mainConditions';
import { distanceSelector } from '../../selectors/mappedData';

/**
 * @description
 * init registration leader form from the logged user data (sessionStore)
 * autofileld
 */
function initFormDataFromConfirmation() {
  if (!mainConditions.isConfirmation.get()) {
    return;
  }

  const confirmationResponse = confirmationStore.tokenData;

  if (!confirmationResponse) {
    return;
  }

  const registrationFields = distanceSelector.registrationFields.get();

  const confirmationCustomFields = mapperUtils.mapBECFToFormCF('', confirmationResponse['fields'] || []);

  const confirmationRegistrationFields = chain(confirmationResponse)
    .thru(mapperUtils.selectBERacerByRegistrationFields(registrationFields))
    .thru(mapperUtils.mapBERacerToFormRacer)
    .value();

  let confirmationAdditionalFields = chain(confirmationResponse)
    .thru(mapperUtils.selectRacerBEAdditionalFields(fieldConditions.racerAdditionalFields()))
    .thru(mapperUtils.mapBERacerToFormRacer);

  if (mainConditions.isTeam.get()) {
    confirmationAdditionalFields = confirmationAdditionalFields
      .thru(mapperUtils.omitClass)
      .thru(mapperUtils.omitWave)
      .thru(mapperUtils.omitDiscipline);
  }

  const confirmation = merge(confirmationRegistrationFields, confirmationAdditionalFields.value(), {
    [fieldIdUtils.staticKeys.customFieldsKey]: confirmationCustomFields,
  });

  transaction(() => {
    const fields = mapperUtils.onlyPriceFields(confirmationResponse['fields'] || []);
    disabledCustomFieldsStore.init(mapperUtils.mapBECFToFormCF(fieldIdUtils.registrationLeader(), fields));
    form.merge(REGISTRATION_FORM, { [fieldIdUtils.staticKeys.groupRegistrations]: [confirmation] });
  });
}

export { initFormDataFromConfirmation };
