const generateShirtOptions = () => [
  {
    key: 1,
    label: 'XS',
    value: 'XS',
  },
  {
    key: 2,
    label: 'S',
    value: 'S',
  },
  {
    key: 3,
    label: 'M',
    value: 'M',
  },
  {
    key: 4,
    label: 'L',
    value: 'L',
  },
  {
    key: 5,
    label: 'XL',
    value: 'XL',
  },
  {
    key: 6,
    label: 'XXL',
    value: 'XXL',
  },
];

const generateShirtValues = () => generateShirtOptions().map((option) => option.value);
const generateShirtLabels = () => generateShirtOptions().map((option) => option.label);

export { generateShirtOptions, generateShirtValues, generateShirtLabels };
