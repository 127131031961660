import { mapperUtils } from 'modules/registration/utils';

import { parseUrlParams } from '../../utils/parseUrlParams';

import { squadService } from '../../services';

import { squadsStore } from '../../stores';

/**
 * @description
 * Load squads list for specific distance
 */
async function loadSquads() {
  const { distanceId } = parseUrlParams();

  if (!distanceId) {
    return;
  }

  const squads = await squadService.load(distanceId);
  const mapFrom = mapperUtils.mapForSquads(squads);
  squadsStore.setValue(mapFrom);
}

export { loadSquads };
