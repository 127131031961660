import { RegistrationFields, AdditionalRegistrationFields, OtherFields, TeamFields, CustomFieldTypes } from '../constants';

function fieldTranslationKey(name: string | nil): TranslationLockedKeys | null {
  switch (name) {
    case RegistrationFields.email:
      return 'settings.email';
    case RegistrationFields.firstname:
      return 'settings.firstname';
    case RegistrationFields.lastname:
      return 'settings.lastname';
    case RegistrationFields.gender:
      return 'settings.gender';
    case RegistrationFields.city:
      return 'settings.city';
    case RegistrationFields.country_id:
      return 'settings.country_id';
    case RegistrationFields.nationality_id:
      return 'settings.nationality_id';
    case RegistrationFields.union:
      return 'settings.union';
    case RegistrationFields.company:
      return 'settings.company';
    case RegistrationFields.address:
      return 'settings.address';
    case RegistrationFields.post_code:
      return 'settings.post_code';
    case RegistrationFields.birthday:
      return 'settings.birthday';
    case RegistrationFields.shoe_size:
      return 'settings.shoe_size';
    case RegistrationFields.shirt_size:
      return 'settings.shirt_size';
    case RegistrationFields.telephone:
      return 'settings.telephone';
    case RegistrationFields.emergency_name:
      return 'settings.emergency_name';
    case RegistrationFields.emergency_phone:
      return 'settings.emergency_phone';
    case RegistrationFields.external_swimrun_id:
      return 'settings.external_swimrun_id';
    case AdditionalRegistrationFields.class_id:
      return 'settings.class';
    case AdditionalRegistrationFields.wave_id:
      return 'registration.wave';
    case AdditionalRegistrationFields.discipline_id:
      return 'settings.discipline';
    case OtherFields.password:
      return 'login.password';
    case OtherFields.password_confirmation:
      return 'login.password_confirmation';
    case TeamFields.team_name:
      return 'registration.teamName';
    default:
      return null;
  }
}

const messages = {
  required: function (fieldName: string | nil): string {
    const fieldMessage = fieldTranslationKey(fieldName);

    if (!fieldMessage) {
      return 'confirmProfile.validation.required';
    }

    return `${fieldMessage} validation.required`;
  },

  pattern1: function (fieldName: string | nil): string {
    const fieldMessage = fieldTranslationKey(fieldName);

    if (!fieldMessage) {
      return 'validation.field validation.pattern1_lndu';
    }

    return `${fieldMessage} validation.pattern1_lndu`;
  },

  invalidFormat: function (fieldName: string | nil): string {
    const fieldMessage = fieldTranslationKey(fieldName);

    if (!fieldMessage) {
      return 'validation.field validation.invalidFormat';
    }

    return `${fieldMessage} validation.invalidFormat`;
  },

  shortPass: function (): TranslationLockedKeys {
    return 'validation.shortPass';
  },

  notEqlPass: function (): TranslationLockedKeys {
    return 'login.password_confirm_not_eql_pass';
  },
};

const generalRules = {
  shouldBeTrue: function (name: string, required: boolean) {
    if (!required) {
      return {
        inclusion: {
          within: [true],
          message: messages.required(name),
        },
      };
    }

    return {
      presence: {
        message: messages.required(name),
      },
      inclusion: {
        within: [true],
        message: messages.required(name),
      },
    };
  },

  textField: function (name: string, required: boolean, minLength?: number) {
    if (!required) {
      return {
        length: {
          maximum: 250,
          minimum: 0,
        },
      };
    }

    return {
      length: {
        maximum: 250,
        minimum: minLength,
      },
      presence: {
        allowEmpty: false,
        message: messages.required(name),
      },
    };
  },

  presence: function (name: string, required: boolean) {
    if (!required) {
      return null;
    }

    return {
      presence: {
        allowEmpty: false,
        message: messages.required(name),
      },
    };
  },

  swimrunId: function (name: string, required: boolean) {
    return {
      ...generalRules.textField(name, required, 3),
      format: {
        pattern: /^([\w\d-_])*$/,
        message: messages.pattern1(name),
      },
    };
  },

  postCode: function (name: string, required: boolean) {
    return {
      ...generalRules.textField(name, required, 3),
      format: {
        pattern: /^[A-Za-z0-9\s-]*$/,
        message: messages.invalidFormat(name),
      },
    };
  },

  phoneNumber: function (_name: string, required: boolean) {
    return {
      phoneNumber: {
        allowEmpty: !required,
      },
    };
  },

  password: function () {
    return {
      presence: {
        message: messages.required(OtherFields.password),
      },
      length: {
        minimum: 6,
        tooShort: messages.shortPass(),
      },
    };
  },

  passwordConfirmation: function () {
    return {
      presence: {
        message: messages.required(OtherFields.password_confirmation),
      },
      equality: {
        attribute: OtherFields.password,
        message: messages.notEqlPass(),
      },
    };
  },
};

function validationRule(name: RegistrationFields | AdditionalRegistrationFields | TeamFields | OtherFields, required: boolean) {
  switch (name) {
    case RegistrationFields.external_swimrun_id:
      return generalRules.swimrunId(name, required);
    case RegistrationFields.post_code:
      return generalRules.postCode(name, required);
    case RegistrationFields.gender:
    case RegistrationFields.birthday:
    case RegistrationFields.country_id:
    case RegistrationFields.nationality_id:
    case RegistrationFields.shirt_size:
    case RegistrationFields.squad_id:
    case AdditionalRegistrationFields.class_id:
    case AdditionalRegistrationFields.wave_id:
    case AdditionalRegistrationFields.discipline_id:
    case AdditionalRegistrationFields.refund_protect:
      return generalRules.presence(name, required);
    case RegistrationFields.telephone:
    case RegistrationFields.emergency_phone:
      return generalRules.phoneNumber(name, required);
    case RegistrationFields.email:
      return generalRules.textField(name, required, 5);
    case RegistrationFields.firstname:
    case RegistrationFields.lastname:
      return generalRules.textField(name, required, 2);
    case RegistrationFields.emergency_name:
    case RegistrationFields.company:
    case RegistrationFields.union:
    case RegistrationFields.city:
    case RegistrationFields.address:
      return generalRules.textField(name, required, 3);

    case RegistrationFields.shoe_size:
    case TeamFields.team_name:
      return generalRules.textField(name, required);
    case OtherFields.password:
      return generalRules.password();
    case OtherFields.password_confirmation:
      return generalRules.passwordConfirmation();
    case OtherFields.terms:
      return generalRules.shouldBeTrue(name, required);
    default:
      return null;
  }
}

function customFieldValidationRule(customFieldType: CustomFieldTypes) {
  switch (customFieldType) {
    case CustomFieldTypes.date:
    case CustomFieldTypes.time:
    case CustomFieldTypes.radio:
    case CustomFieldTypes.checkbox:
    case CustomFieldTypes.drop_down:
    case CustomFieldTypes.extra:
      return generalRules.presence('', true);
    case CustomFieldTypes.textfield:
      return generalRules.textField('', true);

    default:
      return null;
  }
}

export { validationRule, customFieldValidationRule };
