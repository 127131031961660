import { toJS } from 'mobx';
import { refundProtectPrice } from 'modules/registration/selectors/prices/refundProtectPrice';

import { form } from 'stores';

import { AdditionalRegistrationFields, OtherFields, REGISTRATION_FORM, TeamFields } from '../constants';

import { fieldIdUtils, validate } from '../utils';

import { emailExistsStore } from '../stores';

import { fieldConditions } from '../selectors/fieldConditions';
import { formDataSelectors } from '../selectors/formDataSelectors';
import { mainConditions } from '../selectors/mainConditions';
import { schemes } from './schemes';

const validators = {
  validatePasswordFields: function (): boolean {
    if (mainConditions.isLogged.get() || mainConditions.isNoProfile.get()) {
      return true;
    }

    const prefix = fieldIdUtils.groupRegistrationWithIndex(0);
    const scheme = schemes.passwordScheme();
    const leader = formDataSelectors.fetchRegistrationLeader();

    return validate(leader, scheme, prefix);
  },

  validateTerms: function (): boolean {
    const scheme = schemes.termsScheme();
    const isTermsChecked = formDataSelectors.fetchIsTermsChecked();

    if (mainConditions.isLogged.get() || mainConditions.isNoProfile.get()) {
      return true;
    }

    return validate({ [OtherFields.terms]: isTermsChecked }, scheme, null);
  },

  /**
   * @description
   * Validate registration leaders regitration fields, additional fields
   */
  validateRegistrationLeader: function (): boolean {
    if (emailExistsStore.value) {
      return false;
    }

    const prefix = fieldIdUtils.groupRegistrationWithIndex(0);
    const scheme = schemes.racerScheme(prefix);
    const leader = formDataSelectors.fetchRegistrationLeader();

    return validate(leader, scheme, prefix);
  },

  validateLeaderCustomFields: function (): boolean {
    const prefix = [fieldIdUtils.groupRegistrationWithIndex(0), fieldIdUtils.staticKeys.customFieldsKey].join('.');

    const scheme = schemes.customFieldsScheme();
    const leadersCustomFields = formDataSelectors.fetchLeaderCustomFields();

    return validate(leadersCustomFields, scheme, prefix);
  },

  /**
   * @description
   * validate all group registrations with custom fields
   */
  validateGroupRegistrations: function (): boolean {
    return formDataSelectors
      .fetchGroupRegistrationMembers()
      .map((groupRegistration, index) => {
        /**
         * Skip registration leader
         */
        if (index === 0) {
          return true;
        }

        /**
         * Skip not editable group member during edit registration
         */
        if (mainConditions.isEditRegistration() && !groupRegistration.is_editable) {
          return true;
        }

        const racerPrefix = fieldIdUtils.groupRegistrationWithIndex(index);
        const racerScheme = schemes.racerScheme(racerPrefix);
        const racer = groupRegistration;
        const customFieldsPrefix = [fieldIdUtils.groupRegistrationWithIndex(index), fieldIdUtils.staticKeys.customFieldsKey].join('.');
        const customFieldsScheme = schemes.customFieldsScheme();
        const customFields = formDataSelectors.fetchGroupRegistrationCustomFields(index);

        const isRacerValidated = validate(racer, racerScheme, racerPrefix);
        const isCustomFieldsValidated = validate(customFields, customFieldsScheme, customFieldsPrefix);

        return isRacerValidated && isCustomFieldsValidated;
      })
      .every(Boolean);
  },

  /**
   * @description
   * validate all team registration members with custom fields
   */
  validateTeamRegistrationMembers: function (): boolean {
    return formDataSelectors
      .fetchTeamRegistrationMembers()
      .map((teamMember, index) => {
        const racerPrefix = fieldIdUtils.teamMemberWithIndex(index);

        const racerScheme = schemes.racerScheme(racerPrefix);
        const racer = teamMember;

        const customFieldsPrefix = [fieldIdUtils.teamMemberWithIndex(index), fieldIdUtils.staticKeys.customFieldsKey].join('.');
        const customFieldsScheme = schemes.customFieldsScheme();
        const customFields = formDataSelectors.fetchTeamRegistrationMemberCustomFields(index);

        const isRacerValidated = validate(racer, racerScheme, racerPrefix);
        const isCustomFieldsValidated = validate(customFields, customFieldsScheme, customFieldsPrefix);

        return isRacerValidated && isCustomFieldsValidated;
      })
      .every(Boolean);
  },

  validateTeam: function (): boolean {
    const scheme = schemes.teamScheme();

    const team = { [TeamFields.team_name]: formDataSelectors.fetchTeamTeamName() };

    if (fieldConditions.isTeamWaveFieldEnabled.get()) {
      team[AdditionalRegistrationFields.wave_id] = formDataSelectors.fetchTeamWaveId();
    }

    if (fieldConditions.isTeamClassFieldEnabled.get()) {
      team[AdditionalRegistrationFields.class_id] = formDataSelectors.fetchTeamClassId();
    }

    return validate(team, scheme, null);
  },

  validateRefundProtect: function (): boolean {
    const scheme = schemes.refundProtectScheme();
    const leaderRefundProtect = form.fetch(REGISTRATION_FORM, fieldIdUtils.registrationLeaderRefundProtect());

    if (mainConditions.isRefundProtectEnabled.get() && refundProtectPrice.get()) {
      return validate(
        { [AdditionalRegistrationFields.refund_protect]: leaderRefundProtect },
        scheme,
        fieldIdUtils.groupRegistrationWithIndex(0),
      );
    }

    return true;
  },
};

export { validators };
