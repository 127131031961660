import registrationStatus from 'stores/registrationStatus';

import { LOCAL_STORAGE_KEYS } from '../../../constants/localStorage-keys';

import { AnalyticsRegistrationEvents } from '../utils';

import { currentPaymentStore, isPaymentSuccessfullyCompleted, isPaymentFrameOpened } from '../stores';

import { snackActions } from './snacks';

function success() {
  currentPaymentStore.endPayment();
  isPaymentFrameOpened.off();

  snackActions.payment.pendingSnack.clean();
  snackActions.payment.cannotCancelSnack.clean();
  snackActions.payment.cancelSnack.clean();
  snackActions.payment.awaitingSnack.clean();

  snackActions.payment.successSnack.show();
  AnalyticsRegistrationEvents.trackPaymentSuccessful();
  registrationStatus.isRegisteredSuccessfully = true;
  isPaymentSuccessfullyCompleted.on();

  localStorage.removeItem(LOCAL_STORAGE_KEYS.campaignLinkToken);
}

function cancel() {
  currentPaymentStore.endPayment();
  isPaymentFrameOpened.off();

  snackActions.payment.cannotCancelSnack.clean();
  snackActions.payment.pendingSnack.clean();
  snackActions.payment.awaitingSnack.clean();

  AnalyticsRegistrationEvents.trackPaymentAborted();
  snackActions.payment.cancelSnack.show();
}

function pending() {
  snackActions.payment.awaitingSnack.show();
}

const paymentCompleteActions = {
  success,
  cancel,
  pending,
};

export { paymentCompleteActions };
