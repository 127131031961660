import { keys, isNil } from 'lodash';
import Xregexp from 'xregexp';

import { REGISTRATION_BE_ERROR_KEY_PATTERNS } from '../../constants';

import { _addErrors } from './_addErrors';

/**
 * @description
 * Catch errors related to the team registration but not to the registration fields of registration leader or team members
 * team_name, class_id, wave_id
 */
function catchTeamRegistrationErrors(errors: RawError | nil) {
  if (!errors) {
    return;
  }

  const errorKeys = keys(errors);

  const mappedErrors = errorKeys.reduce((processedErrors, errorKey) => {
    const match = Xregexp.exec(errorKey, REGISTRATION_BE_ERROR_KEY_PATTERNS.teamRegistrationErrors());

    if (!match) {
      return processedErrors;
    }

    const teamRegitrationField = match.teamRegitrationField;

    if (isNil(teamRegitrationField)) {
      return processedErrors;
    }

    const oldErrors = errors[errorKey] || [];
    const newErrors = oldErrors.map((error) =>
      Xregexp.replace(error, REGISTRATION_BE_ERROR_KEY_PATTERNS.teamRegistrationErrors(false), '$<teamRegitrationField>'),
    );

    processedErrors[teamRegitrationField] = newErrors;

    return processedErrors;
  }, {});

  _addErrors(mappedErrors);
}

export { catchTeamRegistrationErrors };
