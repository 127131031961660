import { membersService } from 'modules/registration/services';
import { toastUtils } from 'modules/registration/utils/toasts';
import { loadRacerService } from 'modules/startlist/services';

import { ROUTER } from 'src/constants';

import { encodeQuery, history } from 'utils';

import { parseUrlParams } from '../../utils';

import { racerRegistrationStore } from '../../stores';

/**
 * @description
 *
 * Load racer by id to init edit registration
 *
 */
async function loadRacerRegistration() {
  const { editRacerId, distanceId } = parseUrlParams();

  if (!editRacerId || !distanceId) {
    return;
  }

  const racer = await loadRacerService.load(editRacerId);

  if (racer) {
    racerRegistrationStore.setRacer(racer);
    if (racer.is_group_leader) {
      const membersData = await membersService.load(distanceId);
      const groupMembers = membersData?.filter((member) => member.user_id !== racer.user_id);
      groupMembers && racerRegistrationStore.setGroupMembers(groupMembers);
    }
  } else {
    racerRegistrationStore.clear();
    const { raceId } = parseUrlParams();
    const url = encodeQuery.generatePath(ROUTER.RACE_ABOUT_US, { id: raceId });
    toastUtils.invalidTransferLink();
    history.push(url);
  }
}

export { loadRacerRegistration };
