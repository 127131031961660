import { ROUTER } from 'src/constants';
import { overlayStore, sessionStore } from 'src/stores';
import { history, encodeQuery } from 'src/utils';

import { signInModalStore } from '../../stores';

export const generateOnAuthHeader = () => {
  const query = encodeQuery.parseQuery(history.location.search);

  if (!query.invite_token) {
    return null;
  }

  return () => {
    if (sessionStore.isAuth) {
      history.push(ROUTER.PROFILE);
      return;
    }
    signInModalStore.on();
    overlayStore.show();
  };
};
