import { snackStore } from 'stores';

import { SNACK_SCOPES } from '../../../constants';

import { alreadyRegisteredSnack } from './alreadyRegistered';
import { distanceSoldOutSnack } from './distanceSoldOut';
import { invalidInviteTokenSnack } from './invalidInviteToken';
import { registrationClosedSnack } from './registrationClosed';
import { registrationOutdatedSnack } from './registrationOutdated';

function clean() {
  snackStore.removeAll((snack) => snack.options?.scope === SNACK_SCOPES.blockedRegistration);
}

export const blockedRegistration = {
  alreadyRegisteredSnack,
  registrationOutdatedSnack,
  distanceSoldOutSnack,
  invalidInviteTokenSnack,
  registrationClosedSnack,
  clean,
};
