import { reaction } from 'mobx';

import { controlsActions } from '../actions';

import { disposers } from '../stores';

import { couponErrorReactionTrigger } from '../selectors/coupons/couponErrorReactionTrigger';

/**
 * @description
 * Observe is there new errors added for coupons
 * Error types:
 * 1. Is it is invalid coupon code
 * 2. Or there is not enough uses for coupons per amount of group users
 * (users with the same coupon) > coupon.uses_per_coupon
 *
 * Anyway
 *
 * If new errors has been added,
 * triggered reaction will ADD THOSE ERRORS INTO errorsStore
 * And those will be displayed in the form fields
 */
function observeCouponErrors() {
  const disposer = reaction(couponErrorReactionTrigger, () => {
    controlsActions.addCouponErrors();
  });

  disposers.register(disposer);
}

export { observeCouponErrors };
