import { form } from 'stores';

import { FORM_NEW_SQUAD } from '../../constants';

import { newSquadModalStore } from '../../stores';

/**
 * @description
 * hide new squad modal and clean data
 */
export const closeNewSquadModal = () => {
  newSquadModalStore.clear();
  form.clean(FORM_NEW_SQUAD);
};
