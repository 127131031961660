import axios from 'axios';
import shortid from 'shortid';

import { API_GROUP_MEMBERS, API_URL } from 'src/constants';

import { action, request, encodeQuery } from 'utils';

class MembersService {
  @request({ action: shortid() })
  request(distanceId: number): Promise<any> {
    const url = encodeQuery.generatePath(API_URL + API_GROUP_MEMBERS, {
      distanceId,
    });
    return axios.get(url);
  }

  @action({ action: shortid() })
  async load(distanceId: number): Promise<RacersType[] | null> {
    const [status, response] = await this.request(distanceId);

    if (!status) {
      return null;
    }

    return response.data.data;
  }
}

export { MembersService };
