import { transaction } from 'mobx';
import { distanceSelector } from 'modules/registration/selectors/mappedData';
import { SyntheticEvent } from 'react';
import shortid from 'shortid';

import { form } from 'stores';

import { REGISTRATION_FORM } from '../../constants';

import { fieldIdUtils } from '../../utils';

import { formDataSelectors } from '../../selectors/formDataSelectors';
import { cleanErrors } from '../data/clean';

/**
 * @description
 * UI actions to control group sign up form
 */
export const groupRegistrationForm = {
  generateOnTooggleCollapse: (index: number) => (e: SyntheticEvent) => {
    e.stopPropagation();
    const registrationMember = formDataSelectors.fetchGroupRegistrationMember(index);

    if (!registrationMember) {
      return;
    }

    form.onChange(REGISTRATION_FORM, fieldIdUtils.groupRegistrationsOpen(index), !registrationMember.open);
  },

  generateOnDelete: (id: string) => (e: SyntheticEvent) => {
    e.stopPropagation();
    const groupRegistrations = formDataSelectors.fetchGroupRegistrationMembers();

    transaction(() => {
      cleanErrors();

      form.onChange(
        REGISTRATION_FORM,
        fieldIdUtils.staticKeys.groupRegistrations,
        groupRegistrations.filter((item, _index) => item._id !== id),
      );
    });
  },

  /**
   * @description
   * When adding new regisration form
   * all other forms will be closed
   */
  onAdd: (withCountryBasedData = true) => {
    const groupRegistrations = formDataSelectors.fetchGroupRegistrationMembers() || [];
    form.onChange(REGISTRATION_FORM, fieldIdUtils.staticKeys.groupRegistrations, [
      ...groupRegistrations.map((item) => ({ ...item, open: false })),
      {
        _id: shortid(),
        open: true,
      },
    ]);
  },
};

/**
 * @description
 * UI actions to control team sign up form
 */
export const teamRegistrationForm = {
  generateOnTooggleCollapse: (index: number) => (e: SyntheticEvent) => {
    e.stopPropagation();
    const teamMember = formDataSelectors.fetchTeamRegistrationMember(index);

    if (!teamMember) {
      return;
    }

    form.onChange(REGISTRATION_FORM, fieldIdUtils.teamMemberOpen(index), !teamMember.open);
  },

  generateOnDelete: (id: string) => (e: SyntheticEvent) => {
    e.stopPropagation();
    const teamMemebers = formDataSelectors.fetchTeamRegistrationMembers();

    transaction(() => {
      cleanErrors();

      form.onChange(
        REGISTRATION_FORM,
        fieldIdUtils.staticKeys.teamMembers,
        teamMemebers.filter((item, _index) => item._id !== id),
      );
    });
  },

  /**
   * @description
   * When adding new team member
   * all other forms will be closed
   */
  onAdd: () => {
    const teamMembers = formDataSelectors.fetchTeamRegistrationMembers() || [];
    form.onChange(REGISTRATION_FORM, fieldIdUtils.staticKeys.teamMembers, [
      ...teamMembers.map((item) => ({ ...item, open: false })),
      {
        _id: shortid(),
        open: true,
      },
    ]);
  },

  /**
   * @description
   * add min members quantity required to register to the distance
   * those added, cannot be deleted
   */
  initMinMembersForms: () => {
    /**
     * -1 because registration leader already added and considered as a part of a team
     */
    const minMembers = distanceSelector.minMembersInTeam.get() - 1;

    if (Number.isNaN(minMembers) || minMembers <= 0) {
      return;
    }
    const teamMembers = [...Array(minMembers)].map((_el) => ({
      _id: shortid(),
      open: true,
    }));
    form.onChange(REGISTRATION_FORM, fieldIdUtils.staticKeys.teamMembers, teamMembers);
  },
};
