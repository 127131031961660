import { snackStore } from 'stores';

import { SNACK_SCOPES } from '../../../constants';

let snackId: string;

function show() {
  const snackParams = {
    i18key: 'registration.messages.registrationOutdated',
  };

  clean();
  snackId = snackStore.warn(snackParams, { scope: SNACK_SCOPES.blockedRegistration });
}

function clean() {
  snackId && snackStore.removeValue(snackId);
}

const registrationOutdatedSnack = {
  show,
  clean,
};

export { registrationOutdatedSnack };
