import { reverse, uniq } from 'lodash';

import { giftcardStore } from '../../stores';

/**
 * @description
 * Id's of giftcard code fields, ORDERED in the way as user have edited them
 * e.g ['group.3.giftcard_code', 'leader.giftcard_code', 'group.5.giftcard_code', 'group.2.giftcard_code']
 *
 * Order is necessary to correctly display apperance of the errors
 */
function getAllGiftcardFields() {
  const changeFieldsLog = giftcardStore.changedGiftcardFields;

  return uniq(reverse([...changeFieldsLog]));
}

export { getAllGiftcardFields };
