import { countriesStore } from 'stores';

function findCountryCode(id: number): void | null | string {
  const code = countriesStore.findById(id)?.alpha_2_code;

  if (!code) {
    return null;
  }

  return code.toLowerCase();
}

function findCountryCodeByCountryName(countryName: string): string | null {
  const country = countriesStore.findByCountryName(countryName);
  return country?.value.alpha_2_code?.toLowerCase() || null;
}

export { findCountryCode, findCountryCodeByCountryName };
