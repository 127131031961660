import { Observer } from 'mobx-react';
import { Logo } from 'modules/header/components';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { FULL_LOCALES, RESOLUTIONS, LOAD_LANGUAGES } from 'src/constants';
import { Icon } from 'src/styledComponents/Icons';
import { Svg } from 'src/styledComponents/Icons/Svg';
import { PulseLoader } from 'src/styledComponents/Loader/PulseLoader';
import { Headline } from 'src/styledComponents/Typography';

import { Show } from 'components/condition';

import { t } from 'utils';

import { translations } from 'actions';

import { progressStore, windowSize } from 'stores';

import { ButtonBlock, LanguagesBlock, SelectorWrapper } from '../components';

import { handleCloseSelector } from '../actions';

import { selectorStore } from '../stores';

import { Item } from './Item';

const Container = styled.div`
  width: 100%;
  padding: 0 20px;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    width: 800px;
    padding: 0;
  }
`;

export const LanguageSelector: React.FC = () => {
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    handleCloseSelector();
    if (!progressStore.isLoading(LOAD_LANGUAGES) && update) {
      setUpdate(false);
    }
  });

  return (
    <Observer>
      {() => {
        const bodyElement = document.querySelector('body');
        const isActive = !!selectorStore.value;
        const isMobile = windowSize.isLessThan('medium');
        const isLoading = progressStore.isLoading(LOAD_LANGUAGES);

        if (!isActive) {
          bodyElement!.style.overflow = 'auto';
          return null;
        } else {
          bodyElement!.style.overflow = 'hidden';
        }

        const onChange = async (value: availableLocales) => {
          await translations.changeLanguage(value);
          setUpdate(true);
        };

        return (
          <SelectorWrapper isMobile={isMobile}>
            <ButtonBlock>
              <Logo size={isMobile ? 32 : 56} />
              <Svg name='CrossGray' size={isMobile ? 24 : 40} onClick={() => handleCloseSelector()} />
            </ButtonBlock>

            <Container>
              <Headline variant='h2'>{t.staticAsString('locales.title')}</Headline>

              <LanguagesBlock>
                {FULL_LOCALES.map((option) => (
                  <Item key={option.value} onChange={onChange} option={option} />
                ))}
              </LanguagesBlock>
            </Container>

            <Show if={isLoading}>
              <PulseLoader />
            </Show>
          </SelectorWrapper>
        );
      }}
    </Observer>
  );
};
