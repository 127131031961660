import { errorsStore, form } from 'stores';

import { REGISTRATION_FORM, DISTANCES_FORM } from '../../constants';

function cleanForm() {
  form.clean(REGISTRATION_FORM);
  errorsStore.clear(REGISTRATION_FORM);
}

async function cleanErrors() {
  errorsStore.clear(REGISTRATION_FORM);
}

/**
 * @description
 * clean distance form selector
 */
function cleanDistanceSelector() {
  form.clean(DISTANCES_FORM);
}

export { cleanForm, cleanErrors, cleanDistanceSelector };
