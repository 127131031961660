import React from 'react';

import { Dropdown } from 'src/styledComponents/Dropdown';
import { MultiCheckbox } from 'src/styledComponents/MultiCheckbox';
import { PhoneField } from 'src/styledComponents/PhoneField';
import { TextField } from 'src/styledComponents/TextField';

import { Form, InputWrapper } from 'components/form';

import { t } from 'utils';

import { MORE_INFO_FORM_ID } from '../../constants';

import { generateShirtOptions } from '../../../../utils/generateShirtOptions';

import { BloodList } from '../../derivations/bloodList';

type Props = {
  checkboxList: Object[];
  checked: Object[];
  handleLiveUpdate: Function;
  isLoadingProcess: boolean;
  handleOnBlur?: Function;
};

export const MoreInfoForm = ({ checkboxList, checked, handleOnBlur, handleLiveUpdate, isLoadingProcess }: Props) => {
  return (
    <Form id={MORE_INFO_FORM_ID} disableAutofill withScroll>
      <InputWrapper
        name='union'
        settings={{
          label: t.staticAsString('settings.association'),
          placeholder: t.staticAsString('settings.association'),
          required: false,
          requiredMark: false,
          disabled: isLoadingProcess,
          onBlur: handleOnBlur,
        }}
        Component={TextField}
      />
      <InputWrapper
        name='external_swimrun_id'
        settings={{
          label: t.staticAsString('settings.swimrunId'),
          placeholder: t.staticAsString('settings.swimrunId'),
          required: false,
          requiredMark: false,
          disabled: isLoadingProcess,
          onBlur: handleOnBlur,
        }}
        Component={TextField}
      />
      <InputWrapper
        name='sports'
        settings={{
          checkboxList,
          value: checked,
          label: t.staticAsString('settings.sports'),
          required: false,
          requiredMark: false,
          disabled: isLoadingProcess,
          additional: { onChange: handleLiveUpdate },
        }}
        Component={MultiCheckbox}
      />

      <InputWrapper
        name='shirt_size'
        settings={{
          label: t.staticAsString('settings.shirt_size'),
          placeholder: t.staticAsString('settings.shirt_size'),
          required: false,
          requiredMark: false,
          id: 'shirt_size',
          options: generateShirtOptions,
          disabled: isLoadingProcess,
          additional: { onChange: handleLiveUpdate },
        }}
        Component={Dropdown}
      />
      <InputWrapper
        name='shoe_size'
        settings={{
          label: t.staticAsString('settings.shoe_size'),
          placeholder: t.staticAsString('settings.shoe_size'),
          required: false,
          requiredMark: false,
          disabled: isLoadingProcess,
          onBlur: handleOnBlur,
        }}
        Component={TextField}
      />
      <InputWrapper
        name='diseases'
        settings={{
          label: t.staticAsString('settings.diseases'),
          placeholder: t.staticAsString('settings.diseases'),
          required: false,
          requiredMark: false,
          disabled: isLoadingProcess,
          onBlur: handleOnBlur,
        }}
        Component={TextField}
      />
      <InputWrapper
        name='blood_type'
        settings={{
          label: t.staticAsString('settings.blood_type'),
          placeholder: t.staticAsString('settings.blood_type'),
          required: false,
          requiredMark: false,
          id: 'blood_type',
          options: BloodList.valuesForDropdown,
          disabled: isLoadingProcess,
          additional: { onChange: handleLiveUpdate },
        }}
        Component={Dropdown}
      />
      <InputWrapper
        name='emergency_name'
        settings={{
          label: t.staticAsString('settings.emergency_name'),
          placeholder: t.staticAsString('settings.emergency_name'),
          required: false,
          requiredMark: false,
          disabled: isLoadingProcess,
          onBlur: handleOnBlur,
        }}
        Component={TextField}
      />
      <InputWrapper
        name='emergency_phone'
        settings={{
          label: t.staticAsString('settings.ice_number'),
          placeholder: t.staticAsString('settings.ice_number'),
          required: false,
          requiredMark: false,
          disabled: isLoadingProcess,
          onBlur: ({ name, e }) => {
            const { target } = e;
            handleLiveUpdate({ name: [name], value: target?.value });
          },
        }}
        Component={PhoneField}
      />
      <InputWrapper
        name='company'
        settings={{
          label: t.staticAsString('settings.company'),
          placeholder: t.staticAsString('settings.company'),
          required: false,
          requiredMark: false,
          disabled: isLoadingProcess,
          onBlur: handleOnBlur,
        }}
        Component={TextField}
      />
    </Form>
  );
};
