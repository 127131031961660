import { reaction } from 'mobx';

import { onDistanceChange } from '../actions';

import { disposers } from '../stores';

import { distanceSelector } from '../selectors/mappedData';

/**
 * @description
 * Observe loaded distance, and based on changes
 * manage registration observers and form data
 * (manipulate service data)
 */
function observeLoadedDistance() {
  const disposer = reaction(
    () => distanceSelector.id.get(),
    (_id) => {
      onDistanceChange();
    },
    { fireImmediately: true },
  );

  disposers.register(disposer);
}

export { observeLoadedDistance };
