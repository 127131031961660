import { reaction } from 'mobx';

import { sessionStore } from 'stores';

import { dataActions } from '../actions/data';
import { loaderActions } from '../actions/loader';

import { disposers } from '../stores';

/**
 * @description
 * Observe registration leader email
 * and if it has been changed, check if it does not exists on BE
 */
function observeLoggedState() {
  const disposer = reaction(
    () => sessionStore.isAuth,
    async () => {
      loaderActions.checkisEmailExists();
      loaderActions.loadDistance();
      loaderActions.loadDistancesList();
      dataActions.cleanErrors();
      dataActions.initFormDataFromProfile();
    },
  );

  disposers.register(disposer);
}

export { observeLoggedState };
