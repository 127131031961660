import { endGroupProcessPayment } from 'modules/registration/actions/submit/groupRegistrations';
import { RegistrationStatus } from 'modules/registration/constants';
import { registrationService } from 'modules/registration/services';
import { editRegistrationIdStore } from 'modules/registration/stores';
import { preloaders } from 'modules/registration/utils';

const MAX_POLL_COUNT = 5;
let pollCount = 0;

export const checkRegistrationStatus = async (uuid: string) => {
  if (!uuid) {
    return;
  }
  preloaders.submitInProgress.enable();
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
  const response = await registrationService.checkRegistrationStatus(uuid);

  if (response.isOk) {
    if (response.data?.data?.status === RegistrationStatus.completed) {
      const responseRegistrationRacers = await registrationService.getRegistrationRacers(response.data.data.id);
      editRegistrationIdStore.setValue(responseRegistrationRacers?.data?.data?.[0]?.id);

      endGroupProcessPayment();
      preloaders.submitInProgress.disable();
    } else if (response.data?.data?.status === RegistrationStatus.canceled) {
      if (pollCount < MAX_POLL_COUNT) {
        //  Try again, to avoid the bug related with to the user's ability to refuse to pay by card and pay for iDeal
        pollCount += 1;
        setTimeout(() => checkRegistrationStatus(uuid), 1000);
      } else {
        preloaders.submitInProgress.disable();
        return;
      }
    } else if (response.data?.data?.status === RegistrationStatus.pending) {
      setTimeout(() => checkRegistrationStatus(uuid), 1000);
    }
  }
};
