function calculatePercents(value: number | null | void, goal: number | null | void): number {
  if (!value || !goal) {
    return 0;
  }
  /*
   * Changed this from Math.round to Math.trunc for the case when the value is 99.5+ and <100
   * but the goal is always 100%. Math.round will round it to 100 keeping participants
   * from logging the last .5%
   * but Math.trunc will return 99.
   * Math.trunc will also always show the integer for the percent completion in the UI,
   * unlike just the calculation.
   */
  return Math.trunc((value / goal) * 100);
}

export { calculatePercents };
