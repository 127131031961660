import { chain, defaults, isNil, merge, pickBy } from 'lodash';
import { transaction } from 'mobx';
import { controlsActions } from 'modules/registration/actions';
import { fieldConditions } from 'modules/registration/selectors/fieldConditions';
import { formDataSelectors } from 'modules/registration/selectors/formDataSelectors';
import { disabledCustomFieldsStore, disabledRegistrationFieldsStore, racerRegistrationStore } from 'modules/registration/stores';
import { Data } from 'modules/registration/types';

import { form, sessionStore } from 'stores';

import { REGISTRATION_FORM, RegistrationFields } from '../../constants';

import { mainConditions } from '../..//selectors/mainConditions';
import { distanceSelector } from '../..//selectors/mappedData';
import { fieldIdUtils } from '../..//utils';
import { mapperUtils } from '../..//utils/mappers';

/**
 * @description
 * init registration leader form from the logged user data (sessionStore)
 * autofileld
 */
function initFormDataFromDistanceRegistration() {
  if (!mainConditions.isLogged.get() || !mainConditions.isEditRegistration()) {
    return;
  }

  const registrationFields = distanceSelector.registrationFields.get();

  const racerData: any = racerRegistrationStore.racer;

  const groupMembersData = racerRegistrationStore.groupMembers;

  const groupMembersFormData =
    groupMembersData?.map((member: any, index) => {
      controlsActions.groupRegistrationForm.onAdd();
      const formIndex = index + 1;
      const mappedProfileData = chain(member)
        .thru(mapperUtils.selectBERacerByRegistrationFields(registrationFields))
        .thru(mapperUtils.mapBERacerToFormRacer)
        .value();

      const currentRegistrationMemberForm = formDataSelectors.fetchGroupRegistrationMember(formIndex);

      const registrationCustomFields = mapperUtils.mapBECFToFormCF('', member['fields'] || []);

      let additionalFields = chain(member)
        .thru(mapperUtils.selectRacerBEAdditionalFields(fieldConditions.racerAdditionalFields()))
        .thru(mapperUtils.mapBERacerToFormRacer)
        .value();

      let formData: Data.Form.Racer;
      formData = defaults(mappedProfileData, additionalFields, currentRegistrationMemberForm);
      formData = pickBy(formData, (item) => !isNil(item));

      const editRegistrationData = merge(formData, {
        [fieldIdUtils.staticKeys.customFieldsKey]: registrationCustomFields,
        is_editable: Boolean(member.is_editable),
      });

      transaction(() => {
        if (member.is_editable) {
          disabledRegistrationFieldsStore.add([
            `${fieldIdUtils.groupRegistrationWithIndex(formIndex)}.${RegistrationFields.firstname}`,
            `${fieldIdUtils.groupRegistrationWithIndex(formIndex)}.${RegistrationFields.lastname}`,
            `${fieldIdUtils.groupRegistrationWithIndex(formIndex)}.${RegistrationFields.email}`,
          ]);
          const fields = mapperUtils.onlyPriceFields(member.fields || []);
          disabledCustomFieldsStore.add(mapperUtils.mapBECFToFormCF(fieldIdUtils.groupRegistrationWithIndex(formIndex), fields));
        } else {
          disabledRegistrationFieldsStore.add(
            Object.keys(defaults(mappedProfileData, additionalFields)).map(
              (key) => `${fieldIdUtils.groupRegistrationWithIndex(formIndex)}.${key}`,
            ),
          );
          const fields = member.fields || [];
          disabledCustomFieldsStore.add(mapperUtils.mapBECFToFormCF(fieldIdUtils.groupRegistrationWithIndex(formIndex), fields));
          disabledCustomFieldsStore.addCustomFieldsById(fieldIdUtils.distanceFreeCustomFieldsId(formIndex));
        }
      });
      return editRegistrationData;
    }) || [];

  const profileData = sessionStore.getUserData();
  const mappedProfileData = chain(racerData)
    .thru(mapperUtils.selectBERacerByRegistrationFields(registrationFields))
    .thru(mapperUtils.mapBERacerToFormRacer)
    .thru((data) => pickBy(data, (item) => !isNil(item)))
    .value();

  const currentRegistrationLeaderForm = formDataSelectors.fetchRegistrationLeader();

  const registrationCustomFields = mapperUtils.mapBECFToFormCF('', racerData['fields'] || []);

  const profileEmail = { [RegistrationFields.email]: profileData[RegistrationFields.email] };

  let additionalFields = chain(racerData)
    .thru(mapperUtils.selectRacerBEAdditionalFields(fieldConditions.racerAdditionalFields()))
    .thru(mapperUtils.mapBERacerToFormRacer)
    .value();

  let formData: Data.Form.Racer;

  formData = defaults(profileEmail, mappedProfileData, additionalFields, currentRegistrationLeaderForm);

  formData = pickBy(formData, (item) => !isNil(item));

  const editRegistrationData = merge(formData, {
    [fieldIdUtils.staticKeys.customFieldsKey]: registrationCustomFields,
  });

  transaction(() => {
    disabledRegistrationFieldsStore.add([
      `${fieldIdUtils.registrationLeader()}.${RegistrationFields.firstname}`,
      `${fieldIdUtils.registrationLeader()}.${RegistrationFields.lastname}`,
      `${fieldIdUtils.registrationLeader()}.${RegistrationFields.email}`,
    ]);
    const fields = mapperUtils.onlyPriceFields(racerData.fields || []);
    disabledCustomFieldsStore.add(mapperUtils.mapBECFToFormCF(fieldIdUtils.registrationLeader(), fields));
    form.merge(REGISTRATION_FORM, { [fieldIdUtils.staticKeys.groupRegistrations]: [editRegistrationData, ...groupMembersFormData] });
  });
}

export { initFormDataFromDistanceRegistration };
