import { flatten, values } from 'lodash';
import { transaction } from 'mobx';

import { toastUtils } from '../../utils/toasts';

import { stepperStore } from '../../stores';

import { stepperSelector } from '../../selectors/stepper';

const validate = {
  /**
   * @description
   * Validate all fields in current step
   */
  currentStep: function (): boolean {
    if (!stepperStore.currentStep) {
      return true;
    }

    const validators = stepperSelector.stepValidators.get()[stepperStore.currentStep];

    let isValidated = true;

    transaction(() => {
      isValidated = validators.map((validator) => validator()).every(Boolean);
    });

    if (!isValidated) {
      toastUtils.generalValidation();
    }

    return isValidated;
  },

  /**
   * @description
   * Validate all fields in every step
   */
  allSteps: function (): boolean {
    let isValidated = true;

    const validators = flatten(values(stepperSelector.stepValidators.get()));

    transaction(() => {
      isValidated = validators.map((validator) => validator()).every(Boolean);
    });

    if (!isValidated) {
      toastUtils.generalValidation();
    }

    return isValidated;
  },
};

export { validate };
