import { computed, toJS } from 'mobx';
import { EMPTY_DATA } from 'modules/results/constants';

import type { Distance } from 'models';

import { resultsUtil } from '../../utils';

import { ResultStatus, LResult } from '../../types';

/**
 * @description
 * Formatted Split.result_diff || Result.time_result
 * Formula: (Split.finish_time || Result.finish_time) - Result.start_time
 * https://imgur.com/9SbOdHi
 */
function generateFinishResult(result: LResult, checkpointId: number | nil, distance: Distance) {
  return computed(() => {
    const start = resultsUtil.parseTime(result.start_time);
    const finish = resultsUtil.parseTime(result.finish_time);
    const diff = resultsUtil.fetchBEDiff(result, checkpointId);

    if (result.status === ResultStatus.OK) {
      if (start && finish) return resultsUtil.formatTime(start, finish);
    }

    if (result.status === ResultStatus.DNS) return EMPTY_DATA.dns;

    if (checkpointId) {
      const checkpoint = result.splits.find((split) => +split.checkpoint_id === checkpointId);
      if (checkpoint?.time_result_ms) return resultsUtil.formatDiff(checkpoint?.time_result_ms);
      if (result.status === ResultStatus.DNF) return EMPTY_DATA.dnf;
      if (!checkpoint) {
        return EMPTY_DATA.empty;
      }
      return resultsUtil.formatDiff(checkpoint?.time_result_ms);
    }

    if (start && diff) {
      return resultsUtil.formatDiff(diff);
    }

    return resultsUtil.formatFallbackResult(result.status);
  });
}

export { generateFinishResult };
