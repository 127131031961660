import { chain, defaults, pickBy, isNil } from 'lodash';
import { formDataSelectors } from 'modules/registration/selectors/formDataSelectors';
import { Data } from 'modules/registration/types';

import { form, sessionStore } from 'stores';

import { RegistrationFields, REGISTRATION_FORM } from '../../constants';

import { mainConditions } from '../..//selectors/mainConditions';
import { distanceSelector } from '../..//selectors/mappedData';
import { fieldIdUtils } from '../..//utils';
import { mapperUtils } from '../..//utils/mappers';

/**
 * @description
 * init registration leader form from the logged user data (sessionStore)
 * autofileld
 */
function initFormDataFromProfile() {
  if (!mainConditions.isLogged.get() || mainConditions.isEditRegistration()) {
    return;
  }

  const registrationFields = distanceSelector.registrationFields.get();
  const profileData = sessionStore.getUserData();

  const mappedProfileData = chain(profileData)
    .thru(mapperUtils.selectBERacerByRegistrationFields(registrationFields))
    .thru(mapperUtils.mapBERacerToFormRacer)
    .thru((data) => pickBy(data, (item) => !isNil(item)))
    .value();

  const currentRegistrationLeaderForm = formDataSelectors.fetchRegistrationLeader();
  const profileEmail = { [RegistrationFields.email]: mappedProfileData[RegistrationFields.email] };

  let formData: Data.Form.Racer;

  if (mainConditions.isConfirmation.get()) {
    formData = defaults(mappedProfileData, currentRegistrationLeaderForm);
  } else {
    const currentRegistrationLeaderFormWithoutEmptyFields = chain(currentRegistrationLeaderForm)
      .thru((data) => pickBy(data, (item) => !isNil(item)))
      .value();

    formData = defaults(profileEmail, currentRegistrationLeaderFormWithoutEmptyFields, mappedProfileData, currentRegistrationLeaderForm);
  }

  formData = pickBy(formData, (item) => !isNil(item));

  form.merge(REGISTRATION_FORM, { [fieldIdUtils.staticKeys.groupRegistrations]: [formData] });
}

export { initFormDataFromProfile };
