import { blockedRegistration } from './blockedRegistrationSnacks';
import { offlineSnack } from './offline';
import { payment } from './paymentSnacks';

export const snackActions = {
  /**
   * @description
   * Snacks related to payment actions,
   * success, cancel payment etc...
   */
  payment,

  /**
   * @description
   * Permament snacks that should appear during registration
   * sold out distance, already registered user etc...
   */
  blockedRegistration,

  /**
   * @description
   * when offline
   */
  offlineSnack,
};
