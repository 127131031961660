import { authorizedRace } from 'stores';

import { raceSelector } from '../../selectors/mappedData';

/**
 * @description
 * handling race with property is_secret:true
 * will call an password validation form
 * with further logic for registration with token
 */
function authorizeRace() {
  const id = raceSelector.id.get();
  const isSecret = raceSelector.isSecret.get();

  if (!id) {
    return;
  }

  authorizedRace.addOrUpdateValue(id, { isSecret });
  authorizedRace.setActive(id);
}

export { authorizeRace };
