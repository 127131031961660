import { sessionStore } from 'src/stores';
import { fireToast, t } from 'src/utils';

import { transferStore } from '../stores';

import { mainConditions } from '../selectors/mainConditions';
import { redirectToAbout } from './redirectToAbout';

export const checkIfInvalidTransfer = () => {
  if (!mainConditions.isTransferredRegistration.get()) return false;

  if (!sessionStore.isAuth) return false;

  const sessionEmail = (sessionStore.session.user?.email || '').toLowerCase();
  const tokenEmail = (transferStore.tokenData?.email || '').toLowerCase();

  if (sessionEmail !== tokenEmail) {
    redirectToAbout();

    fireToast(t.staticAsString('registration.transfer.invalidSession'), 'warning');
    return true;
  }

  return false;
};
