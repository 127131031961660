import { addCouponErrors } from './addCouponErrors';
import { addGiftcardErrors } from './addGifrcardErrors';
import { generateOnAuthHeader } from './auth';
import { authorizeRace } from './authorizeRace';
import { closeNewSquadModal } from './closeNewSquadModal';
import { groupRegistrationForm, teamRegistrationForm } from './form';
import { openNewSquadModal } from './openNewSquadModal';
import { validate } from './validate';

/**
 * @description
 * UI actions, that should be conntected to the react events
 * but can be called directly as well
 *
 * for example: onTooggleCollapse for the form
 *
 */
export const controlsActions = {
  /**
   * @description
   * UI actions to control group sign up form
   */
  groupRegistrationForm,

  /**
   * @description
   * UI actions to control team sign up form
   */
  teamRegistrationForm,

  /**
   * @description
   * handling race with property is_secret:true
   * will call an password validation form
   * with further logic for registration with token
   */
  authorizeRace,

  /**
   * @description
   * Execute validation on the form
   */
  validate,

  /**
   * @description
   * Collect all invalid coupons in the form
   * and add those errors to the errorsStore(connect to the form)
   */
  addCouponErrors,

  /**
   * @description
   * Collect all invalid giftcards in the form
   * and add those errors to the errorsStore(connect to the form)
   */
  addGiftcardErrors,

  openNewSquadModal,
  closeNewSquadModal,
  generateOnAuthHeader,
};
