import { transaction } from 'mobx';
import { submitEditRegistration } from 'modules/registration/actions/submit/editRegistration';
import { RegistrationStepEnum } from 'modules/registration/constants';
import { priceSelectors } from 'modules/registration/selectors/prices';
import { refundProtectPrice } from 'modules/registration/selectors/prices/refundProtectPrice';

import { windowSize } from 'stores';
import scrollError from 'stores/scrollError';

import { preloaders } from '../../utils';

import { isPayOnSiteSelectedStore, stepperStore } from '../../stores';

import { mainConditions } from '../../selectors/mainConditions';
import { distanceSelector } from '../../selectors/mappedData';
import { controlsActions } from '../controls';
import { cleanErrors } from '../data/clean';
import { submitConfirmation } from './confirmation';
import { submitGroupRegistrations } from './groupRegistrations';
import { submitTeamRegistration } from './teamRegistration';
import { submitTransferRegistration } from './transfer';

async function onSubmit() {
  const isPayOnSite = distanceSelector.isPayOnSiteEnabled.get() && isPayOnSiteSelectedStore.value;

  const isMobile = windowSize.isLessThan('medium');
  scrollError.toggleTrigger(false);
  await cleanErrors();
  scrollError.toggleTrigger(true);

  let isInterrupted: boolean = false;

  const freeOfChargeDistance = priceSelectors.totalPrice.get() === 0 || isPayOnSite;

  transaction(() => {
    controlsActions.addCouponErrors();

    if (!controlsActions.validate.currentStep()) {
      isInterrupted = true;
      return;
    }

    isInterrupted = stepperStore.isLastStep && !controlsActions.validate.allSteps();
  });

  if (isInterrupted) {
    return;
  }

  if (!stepperStore.isLastStep && freeOfChargeDistance) {
    stepperStore.completeStep();
    stepperStore.nextStep();
    stepperStore.completeStep();
  }

  if (!stepperStore.isLastStep) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    if (
      (stepperStore.currentStep === RegistrationStepEnum.registration &&
        mainConditions.isRefundProtectEnabled.get() &&
        refundProtectPrice.get()) ||
      (mainConditions.isCouponCodeEnabled.get() && isMobile) ||
      isMobile
    ) {
      stepperStore.completeStep();
      stepperStore.nextStep();
      preloaders.submitInProgress.disable();
      return;
    } else {
      stepperStore.completeStep();
      stepperStore.nextStep();
      preloaders.submitInProgress.disable();
    }
  }

  if (stepperStore.isLastStep) {
    stepperStore.completeStep();
  }

  preloaders.submitInProgress.enable();

  if (mainConditions.isEditRegistration()) {
    await submitEditRegistration();
    preloaders.submitInProgress.disable();
    return;
  }

  if (mainConditions.isPageRegistrationSingleWithCustomFields.get() || mainConditions.isPageRegistrationSingleWithoutCustomFields.get()) {
    await submitGroupRegistrations();
  }

  if (mainConditions.isPageRegistrationTeamWithCustomFields.get() || mainConditions.isPageRegistrationTeamWithoutCustomFields.get()) {
    await submitTeamRegistration();
  }

  if (mainConditions.isPageConfirmationWithCustomFields.get() || mainConditions.isPageConfirmationWithoutCustomFields.get()) {
    await submitConfirmation();
  }

  if (
    mainConditions.isPageTransferConfirmationWithCustomFields.get() ||
    mainConditions.isPageTransferConfirmationWithoutCustomFields.get()
  ) {
    await submitTransferRegistration();
  }

  preloaders.submitInProgress.disable();
}

export { onSubmit };
