import { keys, isNil } from 'lodash';
import Xregexp from 'xregexp';

import { REGISTRATION_BE_ERROR_KEY_PATTERNS } from '../../constants';

import { fieldIdUtils } from '../../utils';

import { _addErrors } from './_addErrors';

/**
 * @description
 * Catch all errors for group registrants that related to registration fields and additional fields
 * constants.RegistrationFields and constants.AdditionalRegistrationFields
 *
 * remap errors to the frontend format of fieldid
 *
 * Also remove error service keys like 'group.0.registration_fields_values'
 *
 * Custom fields not included
 */
function catchGroupRegistrationAdditionalFields(errors: RawError | nil) {
  if (!errors) {
    return;
  }

  const errorKeys = keys(errors);

  const mappedErrors = errorKeys.reduce((processedErrors, errorKey) => {
    const match = Xregexp.exec(errorKey, REGISTRATION_BE_ERROR_KEY_PATTERNS.groupRegistrationAdditionalFields(true));

    if (!match) {
      return processedErrors;
    }

    let { fieldName } = match;
    const groupRegistrantIndex = parseInt(match.groupRegistrantIndex);

    if (isNil(groupRegistrantIndex)) {
      return processedErrors;
    }

    const newErrorKey = `${fieldIdUtils.groupRegistrationWithIndex(groupRegistrantIndex)}.${fieldName}`;

    const oldErrors = errors[errorKey] || [];
    const newErrors = oldErrors.map((error) =>
      Xregexp.replace(error, REGISTRATION_BE_ERROR_KEY_PATTERNS.groupRegistrationAdditionalFields(false), '$<fieldName>'),
    );

    processedErrors[newErrorKey] = newErrors;

    return processedErrors;
  }, {});

  _addErrors(mappedErrors);
}

export { catchGroupRegistrationAdditionalFields };
