import { trimEnd } from 'lodash';
import { matchPath } from 'react-router-dom';

import { ROUTER_WITH_LOCALES } from 'src/constants';

import { history } from 'utils/history';

export const parseRaceId = () => {
  const match = matchPath(history.location.pathname, { path: trimEnd(ROUTER_WITH_LOCALES.RACE_ABOUT_US, '/about'), exact: false });
  const raceId = match?.params?.id;
  return raceId;
};
