import { reaction } from 'mobx';

import { ROUTER } from 'src/constants';
import { history, encodeQuery, fireToast, t, app } from 'src/utils';

import { sessionStore } from 'stores';

import { parseUrlParams } from '../utils';
import { toastUtils } from '../utils/toasts';

import { initFormDataFromTransferRegistration } from '../actions/data/initFormDataFromTransferRegistration';
import { loadTransferring } from '../actions/loader/loadTransferring';

import { transferStore, disposers } from '../stores';

/**
 * @description
 * NOTE,
 * This added to observe if data by token is invalid
 */
export function observeInvalidRequestToken() {
  const disposer = reaction(
    () => transferStore.invalidTransferTokenStatus,
    async () => {
      if (transferStore.invalidTransferTokenStatus) {
        const sessionEmail = (sessionStore.session.user?.email || '').toLowerCase();
        const tokenEmail = (transferStore.tokenData?.email || '').toLowerCase();

        if (sessionEmail !== tokenEmail) {
          sessionStore.logout();
          fireToast(t.staticAsString('registration.transfer.invalidSession'), 'warning');
          await loadTransferring(), initFormDataFromTransferRegistration();
          return;
        }

        const { raceId } = parseUrlParams();
        const url = encodeQuery.generatePath(ROUTER.RACE_ABOUT_US, { id: raceId });
        toastUtils.invalidTransferAnymoreLink();
        history.push(url);
      }
    },
  );

  disposers.register(disposer);
}
