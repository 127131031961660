import axios from 'axios';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { API_DISTANCES, API_URL } from 'src/constants';

import { request, action } from 'utils';

class DistancesListService {
  @request({ action: shortid() })
  request(raceId: number): any {
    const url = generatePath(API_URL + API_DISTANCES, { id: raceId });
    return axios.get(url, { params: { search: 'is_visible:1', searchFields: 'is_visible:=', searchJoin: 'AND', limit: 5000 } });
  }

  @action({ action: shortid() })
  async load(raceId: number): Promise<null | DistanceType[]> {
    const [isOk, response] = await this.request(raceId);

    if (isOk) {
      return response.data.data;
    }

    return null;
  }
}

export { DistancesListService };
