import { toJS } from 'mobx';
import { Observer } from 'mobx-react';
import * as React from 'react';
import PhoneInputComponent from 'react-phone-input-2';

import { LOAD_LANGUAGES, PREFERED_COUNTRIES } from 'src/constants';

import { Hide } from 'components/condition';

import { countriesStore, progressStore } from 'stores';

import { findCountryCodeByCountryName } from '../../modules/racersTable/utils/shared/findCountryCode';
import { InputWrapComponent } from '../InputTools';
import { PhoneFieldWrapper } from './PhoneFieldWrapper';

interface CustomInputProps extends BaseInputProps {
  inputName?: string;
  country?: string;
}

export const PhoneField: React.FC<CustomInputProps> = (props) => {
  const { autoComplete, requiredMark, errorMessage, label, name, value, disabled, inputName, country } = props;
  const phoneTestSelector = testAnchors.useField(name, TEST_ANCHORS.fieldStructure.root);

  const handleChange = (phone: string, countryData: Object, e, formattedValue) => {
    const { onChange, name } = props;
    onChange({
      name,
      value: {
        phone: formattedValue,
        countryData: formattedValue ? countryData : null,
      },
    });
  };

  const handleBlur = (e) => {
    const { onBlur, name } = props;

    onBlur?.({
      name,
      e,
    });
  };

  return (
    <InputWrapComponent
      errorMessage={errorMessage}
      disabled={disabled}
      label={label}
      requiredMark={requiredMark}
      name={name}
      value={value?.phone || ''}
    >
      <PhoneFieldWrapper disabled={disabled} hasError={Boolean(errorMessage)} isEmpty={!value?.phone && !Boolean(country)}>
        <Observer>
          {() => {
            const isLoadingLocale = progressStore.isLoading(LOAD_LANGUAGES);
            const allCountriesForPhoneField = countriesStore.translatedValues();
            const countryData = value?.countryData;
            return (
              <Hide if={isLoadingLocale}>
                <PhoneInputComponent
                  containerClass={disabled ? 'disabled' : ''}
                  preferredCountries={PREFERED_COUNTRIES}
                  value={value?.phone || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={disabled}
                  enableSearch
                  enableLongNumbers
                  disableSearchIcon
                  localization={allCountriesForPhoneField}
                  isValid={() => true}
                  inputProps={
                    value?.phone
                      ? { autoComplete, name: inputName, ...phoneTestSelector }
                      : {
                          autoComplete,
                          name: inputName,
                          ...phoneTestSelector,
                          readOnly: !country && !countryData?.countryCode,
                          placeholder: !countryData?.countryCode ? 'Please select country first' : 'Enter phone number',
                        }
                  }
                  country={
                    value?.phone
                      ? country?.toLowerCase() || ''
                      : findCountryCodeByCountryName(country || '') || countryData?.countryCode || ''
                  }
                />
              </Hide>
            );
          }}
        </Observer>
      </PhoneFieldWrapper>
    </InputWrapComponent>
  );
};
