import { disposers, isOfflineStore } from '../stores';

function observeInternetConnection() {
  window.addEventListener('offline', offlineListener);
  window.addEventListener('online', onlineListener);

  disposers.register(() => {
    window.removeEventListener('offline', offlineListener);
    window.removeEventListener('online', onlineListener);
  });
}

function offlineListener() {
  isOfflineStore.on();
}

function onlineListener() {
  isOfflineStore.off();
}

export { observeInternetConnection };
