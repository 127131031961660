import { parseUrlParams } from '../../utils';

import { distancesListService } from '../../services';

import { raceDistancesListStore } from '../..//stores';

/**
 * @description
 * load distances list for the dropdown
 * load race, distance, and distances list for the dropdown, and registration ttl
 */
async function loadDistancesList() {
  const { raceId } = parseUrlParams();
  const distances = await distancesListService.load(raceId);
  raceDistancesListStore.setValue(distances || []);
}

export { loadDistancesList };
