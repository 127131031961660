import { reaction } from 'mobx';

import { checkIfSmartLinkValid } from '../actions';

import { disposers, smartLinkStore } from '../stores';

import { mainConditions } from '../selectors/mainConditions';
import { distanceSelector } from '../selectors/mappedData';

function observeSmartLink() {
  const disposer = reaction(
    () => distanceSelector.id.get(),
    (id, prevId) => {
      if (!prevId) {
        return;
      }
      if (mainConditions.isSmartLinkRegistration()) {
        checkIfSmartLinkValid();
      }
      if (!mainConditions.isSmartLinkRegistration()) {
        smartLinkStore.clear();
      }
    },
  );

  disposers.register(disposer);
}

export { observeSmartLink };
