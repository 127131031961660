import { reaction } from 'mobx';

import { snackActions } from '../actions/snacks';

import { disposers, paymentTrackerStore, currentPaymentStore } from '../stores';

import { distanceSelector } from '../selectors/mappedData';

/**
 * @description
 * NOTE,
 * Track and block registration if there are pending payment
 * to display pending payment snack with timer
 */
function observePendingPayments() {
  const disposer = reaction(
    () => {
      const distanceId = distanceSelector.id.get();

      if (!distanceId) {
        return null;
      }

      return paymentTrackerStore.isPending(distanceId);
    },
    () => {
      const distanceId = distanceSelector.id.get();
      const payment = paymentTrackerStore.findPendingPaymentByDistance(Number(distanceId));

      if (currentPaymentStore.isPaymentInProgress || !distanceId || !payment) {
        snackActions.payment.pendingSnack.clean();
        return;
      }

      const createdAt = payment.value.created_at;
      snackActions.payment.pendingSnack.show(createdAt);
    },
  );

  disposers.register(disposer);
}

export { observePendingPayments };
