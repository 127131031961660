import { reverse, uniq } from 'lodash';

import { couponsStore } from '../../stores';

/**
 * @description
 * Id's of coupon code fields, ORDERED in the way as user have edited them
 * e.g ['group.3.coupon_code', 'leader.coupon_code', 'group.5.coupon_code', 'group.2.coupon_code']
 *
 * Order is necessary to correctly display apperance of the errors
 */
function getAllCouponFields() {
  const changeFieldsLog = couponsStore.changedCouponFields;

  return uniq(reverse([...changeFieldsLog]));
}

export { getAllCouponFields };
