import { parseUrlParams } from '../../utils';

import { transferService } from '../../services';

import { transferStore } from '../../stores';

/**
 * @description
 *
 * Load racer by token to init confirmation
 *
 * init user in store if needed
 * logout user if token is valid
 */
export async function loadTransferring() {
  const { requestToken } = parseUrlParams();

  if (!requestToken) {
    return;
  }

  const response = await transferService.loadTransferredRegistration(requestToken);

  if (response && typeof response !== 'number') {
    const data = {
      ...response.racer,
      email: response.transferRegistrationRequest.email,
    };
    transferStore.setTokenData(requestToken, data, response.transferSettings);
  } else {
    transferStore.setInvalidInviteTokenStatus(response);
  }
}
