import { debouncedCheckisEmailExists, checkisEmailExists } from './checkIsEmailExists';
import { initCouponCode } from './initCouponCode';
import { initGiftcard } from './initGiftcard';
import { loadBaseData } from './loadBaseData';
import { loadDistance } from './loadDistance';
import { loadDistancesList } from './loadDistancesList';
import { loadRace } from './loadRace';
import { loadSquads } from './loadSquads';
import { loadWaves } from './loadWaves';

const loaderActions = {
  /**
   * @description
   * load basic data needed for the registration
   * load race, distance, and distances list for the dropdown
   */
  loadBaseData,
  /**
   * @description
   * Load distance into the store based on the option from the dropdown that user selects
   */
  loadDistance,

  /**
   * @description
   * load distances list for the dropdown
   * load race, distance, and distances list for the dropdown, and registration ttl
   */
  loadDistancesList,

  /**
   * @description
   * Load race based on the url params
   */
  loadRace,
  /**
   * @description
   * Check is registraton leader email already taken
   * mark it in the store,
   * with debounce
   */
  debouncedCheckisEmailExists,

  /**
   * @description
   * Check is registraton leader email already taken
   * mark it in the store,
   * without debounce
   */
  checkisEmailExists,

  /**
   * @description
   * Check is current coupon code is valid and init it if it is
   */
  initCouponCode,

  /**
   * @description
   * Check is current giftcard code is valid and init it
   */
  initGiftcard,

  /**
   * @description
   * Load waves list for specific distance
   */
  loadWaves,

  /**
   * @description
   * Load squads list for specific distance
   */
  loadSquads,
};

export { loaderActions };
