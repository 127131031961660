import { PaymentTrackerService } from 'services';

import { paymentTrackerStore } from '../stores';

import { ConfirmationService } from './confirmation';
import { CouponCodeService } from './couponCode';
import { DistanceService } from './distance';
import { DistancesListService } from './distancesList';
import { EmailExistsService } from './emailExists';
import { GiftcardService } from './giftcard';
import { MembersService } from './members';
import { PaymentConfigService } from './paymentConfig';
import { RaceService } from './race';
import { RegistrationService } from './registration';
import { SquadService } from './squadService';
import { TransferringService } from './transferring';
import { UpdateProfileService } from './updateProfile';
import { WavesService } from './waves';

const paymentTrackerService = new PaymentTrackerService(paymentTrackerStore);
const registrationService = new RegistrationService();
const confirmationService = new ConfirmationService();
const transferService = new TransferringService();
const couponCodeService = new CouponCodeService();
const giftcardService = new GiftcardService();
const distanceService = new DistanceService();
const distancesListService = new DistancesListService();
const raceService = new RaceService();
const emailExistsService = new EmailExistsService();
const updateProfileService = new UpdateProfileService();
const paymentConfigService = new PaymentConfigService();
const wavesService = new WavesService();
const squadService = new SquadService();
const membersService = new MembersService();

export {
  paymentTrackerService,
  registrationService,
  confirmationService,
  couponCodeService,
  giftcardService,
  distanceService,
  raceService,
  distancesListService,
  emailExistsService,
  updateProfileService,
  paymentConfigService,
  wavesService,
  squadService,
  transferService,
  membersService,
};
export * from './afterPaymentHandler';
export * from './squadService';
export * from './smartLinkCheck';
