import { computed } from 'mobx';
import { locationStore, recentLocationStore } from 'modules/search/stores';

import { HEADER_FORM } from 'src/constants';
import { form } from 'src/stores';

import { latinizeLocation, mapFilters } from '../utils';

import { onlyRaceIdStore } from '../stores';

export const racesFilters = computed(() => {
  const date = form.fetch(HEADER_FORM, 'date');
  const distance = form.fetch(HEADER_FORM, 'distance');
  const sports = form.fetch(HEADER_FORM, 'sports');

  return [date, distance, sports];
});

export const requestFilters = computed((): AnyObject => {
  const formValues = form.fetchWithoutObservable<AnyObject>(HEADER_FORM) || {};
  const onlyRaceId = onlyRaceIdStore.value;
  return mapFilters({ ...formValues, only_internal_registration: onlyRaceId });
});

export const latinizeFilters = async () => {
  const filters = requestFilters.get();
  const latinizeFilters = 'location' in filters ? await latinizeLocation(filters) : filters;
  return latinizeFilters;
};
