import axios from 'axios';
import shortid from 'shortid';

import { API_URL, API_REGISTRATION_CONFIG } from 'src/constants';

import { request, action } from 'utils';

class PaymentConfigService {
  @request({ action: shortid() })
  async request(): Promise<any> {
    return axios.get(`${API_URL}${API_REGISTRATION_CONFIG}`);
  }

  @action({ action: shortid() })
  async loadRegistrationTTL(): Promise<number | nil> {
    const [status, response] = await this.request();

    if (status) {
      return Number(response.data.registration_ttl);
    }

    return null;
  }
}

export { PaymentConfigService };
