import axios from 'axios';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { API_DISTANCE_V2, API_V2_URL } from 'src/constants';

import { request, action } from 'utils';

class DistanceService {
  @request({ action: shortid() })
  request(distanceId: number, params: AnyObject): any {
    const url = generatePath(API_V2_URL + API_DISTANCE_V2, { id: distanceId });
    return axios.get(url, { params });
  }

  @action({ action: shortid() })
  async load(distanceId: number, params: AnyObject): Promise<null | DistanceType> {
    const [isOk, response] = await this.request(distanceId, params);

    if (isOk) {
      return response.data.data;
    }

    return null;
  }
}

export { DistanceService };
