import { geocodeByAddress } from 'src/utils';

export const latinizeLocation = async (filters: AnyObject): Promise<AnyObject> => {
  let { location } = filters;

  if (location) {
    try {
      const results = await geocodeByAddress(location);

      if (!results.length) {
        return {};
      }
      const result = results[0];
      Object.assign(filters, { location: result.address_components[0].long_name });
    } catch (error) {
      console.error('Error latinizing location', error);
      return {};
    }
  }

  return filters;
};

export const saveOriginalName = (location: string) => {
  sessionStorage.setItem('originalLocationName', JSON.stringify(location));
};
