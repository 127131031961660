import { PaymentMethod } from 'modules/registration/constants';
import { formDataSelectors } from 'modules/registration/selectors/formDataSelectors';

import { paymentTrackerService } from '../../services';

import { disposers, currentPaymentStore } from '../../stores';

import { distanceSelector, raceSelector } from '../../selectors/mappedData';

const TIMEOUT_DELAY = 8000;

/**
 * TODO,
 * remove when live data streaming will be available
 * @description
 * Infinite loop, to load registrations(payments) per distance
 * will be used to block payment if there is another payment in progress
 */
export function paymentsByDistance() {
  let isLoopActive = true;
  let timeoutId: any;

  const disposer = () => {
    isLoopActive = false;
    timeoutId && clearTimeout(timeoutId);
  };

  const iteration = () => {
    timeoutId = setTimeout(async () => {
      if (!isLoopActive || formDataSelectors.fetchLeaderPaymentMethod() === PaymentMethod.creditCard) {
        return;
      }

      const distanceId = distanceSelector.id.get();
      const raceId = raceSelector.id.get();

      /**
       * Payment by distance should not be performed when there is registration payment in progress
       */
      if (!distanceId || !raceId || currentPaymentStore.isPaymentInProgress) {
        iteration();
        return;
      }

      await paymentTrackerService.loadDistancesPayments(raceId, distanceId);

      if (isLoopActive) {
        iteration();
      }
    }, TIMEOUT_DELAY);
  };

  iteration();
  disposers.register(disposer);
}
