import { reaction } from 'mobx';

import { disposers, registrationClosedModalStore } from '../stores';

import { mainConditions } from '../selectors/mainConditions';

export const observeRegistrationClosedModal = () => {
  const disposer = reaction(
    () => [
      mainConditions.isRegistrationOutdated.get(),
      mainConditions.isRegistrationClosed.get(),
      mainConditions.isRegistrationOutdatedForPushPayment.get(),
    ],
    () => {
      if (
        mainConditions.isRegistrationOutdatedForPushPayment.get() ||
        mainConditions.isRegistrationOutdated.get() ||
        mainConditions.isRegistrationClosed.get()
      ) {
        registrationClosedModalStore.on();
      }
    },
  );
  disposers.register(disposer);
};
