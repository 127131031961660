import { chain, merge } from 'lodash';
import { transaction } from 'mobx';

import { form } from 'src/stores';

import { REGISTRATION_FORM, RegistrationFields } from '../../constants';

import { fieldIdUtils, mapperUtils } from '../../utils';

import { transferStore, disabledCustomFieldsStore } from '../../stores';

import { fieldConditions } from '../../selectors/fieldConditions';
import { mainConditions } from '../../selectors/mainConditions';
import { distanceSelector } from '../../selectors/mappedData';

/**
 * @description
 * init data from transfer registration
 * autofilled racer
 */
export function initFormDataFromTransferRegistration() {
  if (!mainConditions.isTransferredRegistration.get()) {
    return;
  }

  const racerData: any = transferStore.tokenData || {};

  if (!racerData) {
    return;
  }

  const registrationFields = distanceSelector.registrationFields.get();

  const transferCustomFields = mapperUtils.mapBECFToFormCF('', racerData.fields || []);

  const transferRegistrationFields = chain(racerData)
    .thru(mapperUtils.selectBERacerByRegistrationFields(registrationFields))
    .thru(mapperUtils.mapBERacerToFormRacer)
    .thru(mapperUtils.omitRegistrationFieldsExcept([RegistrationFields.email]))
    .value();

  let transferAdditionalFields = chain(racerData)
    .thru(mapperUtils.selectRacerBEAdditionalFields(fieldConditions.racerAdditionalFields()))
    .thru(mapperUtils.mapBERacerToFormRacer)
    .thru(mapperUtils.omitClass);

  if (mainConditions.isTeam.get()) {
    transferAdditionalFields = transferAdditionalFields
      .thru(mapperUtils.omitClass)
      .thru(mapperUtils.omitWave)
      .thru(mapperUtils.omitDiscipline);
  }

  const confirmation = merge(transferRegistrationFields, transferAdditionalFields.value(), {
    [fieldIdUtils.staticKeys.customFieldsKey]: transferCustomFields,
  });

  transaction(() => {
    const fields = mapperUtils.onlyPriceFields(racerData.fields || []);
    disabledCustomFieldsStore.init(mapperUtils.mapBECFToFormCF(fieldIdUtils.registrationLeader(), fields));
    form.merge(REGISTRATION_FORM, { [fieldIdUtils.staticKeys.groupRegistrations]: [confirmation] });
  });
}
