import { transaction } from 'mobx';
import { totalPrice } from 'modules/registration/selectors/prices/totalPrice';
import { AnalyticsRegistrationEvents } from 'modules/registration/utils';

import { authorizedRace } from 'stores';
import registrationStatus from 'stores/registrationStatus';

import { registrationService } from '../../services';

import { isPaymentFrameOpened, isPaymentSucceeded, isPaymentSuccessfullyCompleted, isPayOnSiteSelectedStore } from '../../stores';

import { mainConditions } from '../../selectors/mainConditions';
import { distanceSelector } from '../../selectors/mappedData/distance';
import { raceSelector } from '../../selectors/mappedData/race';
import { constructTeamRegistrationRequest } from '../../selectors/requests';
import { errorsActions } from '../errors';
import { loaderActions } from '../loader';
import { processPayment } from '../processPayment';
import { snackActions } from '../snacks';
import { executeProfileActions } from './profileActions';

async function submitTeamRegistration() {
  if (!(await executeProfileActions())) {
    return;
  }

  const request = constructTeamRegistrationRequest();
  const distanceId = distanceSelector.id.get();
  const raceId = raceSelector.id.get();

  if (!distanceId || !raceId) {
    return;
  }

  /**
   * Token required to register into the secret race
   * is_secret:true
   */
  let registrationToken: string | nil;

  if (mainConditions.isSecretRace.get() && raceId) {
    registrationToken = authorizedRace.findToken(raceId);
  }

  const response = await registrationService.submitTeam(raceId, registrationToken, request);

  if (response.isOk) {
    if (
      isPayOnSiteSelectedStore.value ||
      isPaymentSucceeded.value === 'teamSucceeded' ||
      (!response.data?.value && totalPrice.get() === 0)
    ) {
      endTeamProcessPayment();
    } else {
      processPayment(
        response.data?.value!,
        response.data?.operations!,
        response.data?.registration?.uuid!,
        response.data?.payment?.uuid!,
        response.data?.service_fee!,
        response.data?.payment_method_order!,
      );
    }
  }

  if (!response.isOk) {
    transaction(() => {
      errorsActions.catchGroupRegistrantsErrors(response.errors);
      errorsActions.catchGroupRegistrantCustomFieldErrors(response.errors);
      errorsActions.catchTeamMembersErrors(response.errors);
      errorsActions.catchTeamMembersCustomFieldErrors(response.errors);
      errorsActions.catchTeamRegistrationErrors(response.errors);
      errorsActions.catchRegistrationToastErrors(response.message, response.errors);
    });

    /**
     * reload distance and race to enable snacks error handling, see
     * reactions/observeConditionsForPermanentSnacks.ts
     * TODO, should be replaced with live data streaming
     */
    loaderActions.loadDistance();
    loaderActions.loadRace();
  }
}

const endTeamProcessPayment = () => {
  registrationStatus.isRegisteredSuccessfully = true;
  AnalyticsRegistrationEvents.freeTeamRegistrationSuccessful();
  isPaymentSuccessfullyCompleted.on();
  snackActions.payment.successSnack.show({ freeDistance: true });
  isPaymentFrameOpened.off();
  isPaymentSucceeded.clear();
};

export { submitTeamRegistration, endTeamProcessPayment };
