import { debounce } from 'lodash';
import { transaction } from 'mobx';

import { emailExistsService } from '../../services';

import { emailExistsStore } from '../..//stores';
import { formDataSelectors } from '../../selectors/formDataSelectors';
import { mainConditions } from '../../selectors/mainConditions';
import { registrationLeaderEmailExistsErrorUtil } from '../../validations/registrationLeaderEmailExistsErrorUtil';

/**
 * @description
 * Check is registraton leader email already taken
 * mark it in the store,
 * without debounce
 */
async function checkisEmailExists() {
  const email = formDataSelectors.fetchLeaderEmail();

  let emailExists: boolean = false;

  if (email && !mainConditions.isLogged.get()) {
    emailExists = await emailExistsService.load(email);
  }

  transaction(() => {
    if (emailExists) {
      emailExistsStore.on();
      registrationLeaderEmailExistsErrorUtil.addError();
    } else {
      emailExistsStore.off();
      registrationLeaderEmailExistsErrorUtil.cleanError();
    }
  });
}

/**
 * @description
 * Check is registraton leader email already taken
 * mark it in the store,
 * with debounce
 */
const debouncedCheckisEmailExists = debounce(checkisEmailExists, 500);

export { debouncedCheckisEmailExists, checkisEmailExists };
