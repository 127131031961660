import { FILTERS_HEIGHT } from 'modules/races/constants';

import { AnyStore, Disposers } from 'src/stores';

import { RaceType, FiltersType, GeoPoint } from '../types';

export const racesStore = new AnyStore<RaceType[]>([]);
export const mapPointsStore = new AnyStore<GeoPoint[]>([]);
export const filterStore = new AnyStore<FiltersType>({
  page: 1,
  limit: 20,
});

export const collapseMobileListStore = new AnyStore<boolean>(false);

export const metaStore = new AnyStore<{ total_pages: number; total: number } | null>(null);
export const collapseStore = new AnyStore<boolean>(false);

export const mapControlsStore = new AnyStore<number>(10);
export const mapControlsPositionStore = new AnyStore<number>(FILTERS_HEIGHT);
export const mapBoundsStore = new AnyStore<GeoJSON.BBox | null>(null);
export const mapsStore = new AnyStore<AnyObject>(null);

export const sportTypesStore = new AnyStore<AnyObject[]>([]);
export const mapCardStore = new AnyStore<AnyObject | null>(null);
export const showMapIconStore = new AnyStore<boolean>(false);

export const chipFiltersStore = new AnyStore<DropdownItemType[]>([]);
export const searchLabelStore = new AnyStore<string>('');

export const dropdownStore = new AnyStore<string | null>(null);
export const disposers = new Disposers();
export const scrolledElementStore = new AnyStore();
export const onlyRaceIdStore = new AnyStore<boolean>(false);
