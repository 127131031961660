import { keys, isNil } from 'lodash';
import Xregexp from 'xregexp';

import { REGISTRATION_BE_ERROR_KEY_PATTERNS } from '../../constants';

import { fieldIdUtils } from '../../utils';

import { _addErrors } from './_addErrors';

/**
 * @description
 * Remap and add to errorsStore, BE errors to frontend errors, specifically:
 * ALL team registration members, registration fields errors
 * like second members phone number or email etc...
 *
 * Also remove error service keys like 'group.0.team_members.0.email' and leave only 'email
 *
 * Custom fields not included
 */
function catchTeamMembersErrors(errors: RawError | nil) {
  if (!errors) {
    return;
  }

  const errorKeys = keys(errors);

  const mappedErrors = errorKeys.reduce((processedErrors, errorKey) => {
    const match = Xregexp.exec(errorKey, REGISTRATION_BE_ERROR_KEY_PATTERNS.teamMember(true));

    if (!match) {
      return processedErrors;
    }

    let { fieldName } = match;
    const teamMemberIndex = parseInt(match.teamMemberIndex);

    if (isNil(teamMemberIndex)) {
      return processedErrors;
    }

    const newErrorKey =
      teamMemberIndex === 0
        ? `${fieldIdUtils.groupRegistrationWithIndex(0)}.${fieldName}`
        : `${fieldIdUtils.teamMemberWithIndex(teamMemberIndex - 1)}.${fieldName}`;

    const oldErrors = errors[errorKey] || [];
    const newErrors = oldErrors.map((error) =>
      Xregexp.replace(error, REGISTRATION_BE_ERROR_KEY_PATTERNS.teamMember(false), '$<fieldName>'),
    );

    processedErrors[newErrorKey] = newErrors;

    return processedErrors;
  }, {});

  _addErrors(mappedErrors);
}

export { catchTeamMembersErrors };
