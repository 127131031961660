import { cloneDeep, pick, keys } from 'lodash';
import { toJS } from 'mobx';
import { BloodList } from 'modules/profileSettings/derivations/bloodList';

import { phoneMapper } from 'utils';

import { form, sportTypesStore } from 'stores';

import { MORE_INFO_FORM_ID } from '../constants';

import { FrontendData, BackendData } from '../types';

class Mapper implements MapperMiddleware<FrontendData, BackendData> {
  schema = {
    union: 'union',
    sports: 'sports',
    shoe_size: 'shoe_size',
    shirt_size: 'shirt_size',
    external_swimrun_id: 'external_swimrun_id',
    emergency_name: 'emergency_name',
    emergency_phone: 'emergency_phone',
    diseases: 'diseases',
    blood_type: 'blood_type',
    company: 'company',
  };

  mapFrom(values: BackendData): FrontendData {
    const copy: any = cloneDeep(values);

    // const country = helper.takeCountryData(copy.emergency_phone || '');

    copy.emergency_phone = phoneMapper(copy.emergency_phone);
    copy.blood_type = BloodList.valuesForDropdown().find((option) => option.value === copy.blood_type);
    copy.sports = (copy.sports || []).map((sport: string | any) => sport.id) || [];

    return pick(copy, keys(this.schema)) as any;
  }

  mapOneField(fieldName: keyof FrontendData) {
    const fieldData: AnyObject = moreInfoFormData();
    const value = toJS(fieldData[fieldName]);

    const { schema } = this;

    switch (fieldName) {
      case schema.emergency_phone:
        return { [fieldName]: value?.phone };
      case schema.sports:
        const mappedSports = (value || []).map((id: string | any) => {
          const sport = sportTypesStore.modelValues.find((sport) => sport.value.id === id);

          return sport && toJS(sport.value);
        });
        return { [fieldName]: mappedSports };

      case schema.blood_type:
      case schema.shirt_size:
        return { [fieldName]: value?.value || null };
      default:
        return { [fieldName]: value };
    }
  }

  mapTo(values: FrontendData): BackendData {
    const copy: any = cloneDeep(values);
    let schema = this.schema;

    const keyList = Object.keys(copy);

    // During patch request only difference must be mapped
    const mappedData = keyList.reduce((acc, key) => {
      const processedValue = this._processToValue(key || '', copy[key]);
      return { ...acc, [key]: processedValue };
    }, {});

    return pick(mappedData, keys(schema));
  }

  mapToValidation(data: any) {
    const copy: any = cloneDeep(data);
    copy.emergency_phone = phoneMapper(data.emergency_phone);

    return copy;
  }

  _processToValue(name: string, value: any): any {
    const { schema } = this;

    switch (name) {
      case schema.emergency_phone:
        return value?.phone;
      case schema.sports:
        // [55, 56] =>
        // [{id: 55, title: 'swimming'},
        //  {id: 56, title: 'cycling'}]
        return (value || []).map((id: string | any) => {
          const sport = sportTypesStore.modelValues.find((sport) => sport.value.id === id);

          return sport && sport.value;
        });
      case schema.blood_type:
        return value?.value || null;
      default:
        return value;
    }
  }
}

const mapper = new Mapper();
const moreInfoFormData = (name?: keyof FrontendData) => form.fetch<FrontendData>(MORE_INFO_FORM_ID, name);
export { mapper, moreInfoFormData };
