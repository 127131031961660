import { form } from 'stores';

import { DISTANCES_FORM } from '../../constants';

import { fieldIdUtils, mapperUtils } from '../../utils';

/**
 * @description
 * change distance for the distance selector
 */
function changeDistance(id: number | null) {
  const distanceOption = mapperUtils.distanceIdMapper.mapFrom(Number(id) || null);

  if (distanceOption) {
    form.onChange(DISTANCES_FORM, fieldIdUtils.staticKeys.currentDistance, distanceOption);
  }
}

export { changeDistance };
