import { computed, toJS } from 'mobx';

import { couponsStore } from '../../stores';

import { fetchCouponFormValues } from './_fetchCouponFormValues';
import { generateDuplicatedErrors } from './_generateDuplicatedErrors';
import { getAllCouponFields } from './_getAllCouponFields';

/**
 * @description
 * Errors in handy format -
 * [leader.coupon_code]: 'Error...'
 * [group.2.coupon_code]: 'Error...'
 * key - fieldId
 * value - string
 *
 */
const couponErrors = computed(() => {
  const allFields = getAllCouponFields();
  const formValues = fetchCouponFormValues();

  const generalErrors = couponsStore.invalidValues;
  const duplicatedErrors = generateDuplicatedErrors(allFields, formValues);

  return generalErrors;

  // Temporarily shortcutting couponErrors computation
  const allErrors = allFields.reduce((acc, field) => {
    const code = formValues[field];
    if (duplicatedErrors[field]) {
      acc[field] = duplicatedErrors[field];
      return acc;
    }
    if (generalErrors[code]) {
      acc[field] = generalErrors[code];
      return acc;
    }
    acc[field] = null;
    return acc;
  }, {});
  return allErrors;
});

export { couponErrors };
