import { transaction } from 'mobx';
import { initFormDataFromDistanceRegistration } from 'modules/registration/actions/data/initFormDataFromDistanceRegistration';
import { REGISTRATION_FORM } from 'modules/registration/constants';
import { mainConditions } from 'modules/registration/selectors/mainConditions';

import { form } from 'stores';

import { controlsActions } from '../controls';
import { cleanForm, cleanErrors } from './clean';
import { initFormDataFromConfirmation } from './initFormDataFromConfirmation';
import { initFormDataFromProfile } from './initFormDataFromProfile';
import { initFormDataFromSmartLink } from './initFormDataFromSmartLink';
import { initFormDataFromTransferRegistration } from './initFormDataFromTransferRegistration';

function initForm() {
  transaction(() => {
    cleanForm();
    cleanErrors();
    form.registerForm(REGISTRATION_FORM);

    /**
     * Add the first user - registration leader
     */
    controlsActions.groupRegistrationForm.onAdd(!mainConditions.isLogged.get());

    /**
     * Init team min required members for team registration
     */
    controlsActions.teamRegistrationForm.initMinMembersForms();
    initFormDataFromProfile();
    initFormDataFromDistanceRegistration();
    initFormDataFromConfirmation();
    initFormDataFromTransferRegistration();
    initFormDataFromSmartLink();
  });
}

export { initForm };
