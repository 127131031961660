import axios from 'axios';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { API_URL, API_CONFIRMATION, API_INVITE } from 'src/constants';

import { request, action, encodeQuery } from 'utils';

import { Request, Response } from '../types';

class ConfirmationService {
  @request({ action: shortid() })
  _submitRequest(raceId: number, data: Request.Confirmation.Plain): any {
    const url = generatePath(API_URL + API_CONFIRMATION, { raceId });
    return axios.post(url, data);
  }

  @request({ action: shortid() })
  _loadConfirmationRequest(token: string): any {
    const url = encodeQuery.generatePath(API_URL + API_INVITE, { token });
    return axios.get(url);
  }

  @action({ action: shortid() })
  /**
   * @description
   * submit for the confirmation with token
   */
  async submitConfirmation(
    raceId: number,
    data: Request.Confirmation.Plain,
  ): Promise<{
    data: null | Response.Registration.SingleGroup;
    isOk: boolean;
    errors: RawError;
    message: string | null;
  }> {
    const [isOk, response] = await this._submitRequest(raceId, data);

    return {
      isOk,
      data: response.data,
      errors: response.data.errors,
      message: response.data.message,
    };
  }

  @action({ action: shortid() })
  /**
   * @description
   * load racer confirmation data by token
   */
  async loadConfirmation(token: string): Promise<null | Response.Confirmation.LoadedConfirmation> {
    const [isOk, response] = await this._loadConfirmationRequest(token);

    if (isOk) {
      return response.data.data;
    }

    return null;
  }
}

export { ConfirmationService };
