import { RegistrationStepEnum } from 'modules/registration/constants';
import { parseUrlParams, AnalyticsRegistrationEvents } from 'modules/registration/utils';
import { loadRacerService } from 'modules/results/services';

import registrationStatus from 'stores/registrationStatus';

import {
  editRegistrationIdStore,
  isPaymentFrameOpened,
  isPaymentSucceeded,
  isPaymentSuccessfullyCompleted,
  racerRegistrationStore,
  stepperStore,
} from '../../stores';

import { distanceSelector } from '../../selectors/mappedData/distance';
import { raceSelector } from '../../selectors/mappedData/race';
import { constructEditRegistrationsRequests } from '../../selectors/requests';
import { errorsActions } from '../errors';
import { loaderActions } from '../loader';
import { snackActions } from '../snacks';

async function submitEditRegistration() {
  const { editRacerId } = parseUrlParams();
  const requests = constructEditRegistrationsRequests();
  const distanceId = distanceSelector.id.get();
  const raceId = raceSelector.id.get();

  if (!distanceId || !raceId || !editRacerId) {
    return;
  }

  editRegistrationIdStore.setValue(editRacerId);
  try {
    const responses = await Promise.all(
      requests.map(async (request, index) => {
        const racerId = index === 0 ? racerRegistrationStore.racer?.id : racerRegistrationStore.groupMembers?.[index - 1].id;
        if (racerId) {
          const response = await loadRacerService.updateRacer(racerId, request);
          return response;
        }
      }),
    );
    if (responses.every((response) => response?.isOk)) {
      endEditRegistration();
    } else {
      stepperStore.changeStepIfPossible(RegistrationStepEnum.registration);
      throw {
        message: responses.find((response) => response?.message)?.message,
        errors: responses.reduce((accumulator, response) => ({ ...accumulator, ...response?.errors }), {}),
      };
    }
  } catch (err) {
    const { message, errors } = err;
    errorsActions.catchGroupRegistrantsErrors(errors);
    errorsActions.catchGroupRegistrationAdditionalFields(errors);
    errorsActions.catchGroupRegistrantCustomFieldErrors(errors);
    errorsActions.catchRegistrationToastErrors(message, errors);
    /**
     * reload distance and race to enable snacks error handling, see
     * reactions/observeConditionsForPermanentSnacks.ts
     * TODO, should be replaced with live data streaming
     */
    loaderActions.loadDistance();
    loaderActions.loadRace();
  }
}

const endEditRegistration = () => {
  registrationStatus.isRegisteredSuccessfully = true;
  AnalyticsRegistrationEvents.freeGroupRegistrationSuccessful();
  isPaymentSuccessfullyCompleted.on();
  snackActions.payment.successSnack.show({ freeDistance: true });
  isPaymentFrameOpened.off();
  isPaymentSucceeded.clear();
};

export { submitEditRegistration };
