import transform from 'lodash/transform';
import { computed, makeObservable } from 'mobx';

import { CountryModel } from 'models/country';

import { Loadable } from 'stores/loadable';

export const NORWAY_ID = 166;
export const SWEDEN_ID = 215;
export const GREECE_ID = 86;
const PROMOTE_COUNTRIES = [NORWAY_ID, SWEDEN_ID, GREECE_ID];

class Country extends Loadable<CountriesType, FiltersType> {
  declare filters: FiltersType;

  constructor() {
    super();

    makeObservable(this);
    this.filters = {
      limit: 300,
    };
  }

  @computed
  get modelValues(): Array<CountryModel> {
    return this.values.map((race) => new CountryModel(race));
  }

  getModelValue(id: number): nil | CountryModel {
    return this.modelValues.find(({ value }) => value.id === id);
  }

  valuesForDropdown(promoteAdditionalId?: number): Array<{
    key: number;
    label: string;
    value: number;
  }> {
    const sorter = new window.Intl.Collator('sv', { usage: 'sort' });

    if (promoteAdditionalId && !PROMOTE_COUNTRIES.includes(promoteAdditionalId)) {
      PROMOTE_COUNTRIES.push(promoteAdditionalId);
    }

    const sortedValues = this.modelValues
      .filter((country) => !PROMOTE_COUNTRIES.includes(country.value.id))
      .map((country) => ({
        key: country.value.id,
        label: country.name || '',
        value: country.value.id,
      }))
      .sort((item, nextItem) => {
        return sorter.compare(item.label, nextItem.label);
      });

    const promotedCountries = this.modelValues
      .filter((item) => PROMOTE_COUNTRIES.includes(item.value.id))
      .map((country) => ({
        key: country.value.id * 5,
        label: country.name || '',
        value: country.value.id,
      }));

    return [...promotedCountries, ...sortedValues];
  }

  findByCountryName(name: string): CountryModel | undefined {
    return this.modelValues.find((country) => country.name === name);
  }

  valuesForNationalityDropdown(): Array<{
    key: number;
    label: string;
    value: number;
  }> {
    const sorter = new Intl.Collator('sv', {
      usage: 'sort',
    });

    const sortedValues = this.modelValues
      .filter((country) => !PROMOTE_COUNTRIES.includes(country.value.id))
      .map((el) => ({ label: el.nationalityName(), value: el.value.id, key: el.value.id }))
      .sort((item, nextItem) => sorter.compare(item.label as string, nextItem.label as string));

    const promotedCountries = (this.modelValues || [])
      .filter((item) => PROMOTE_COUNTRIES.includes(item.value.id))
      .map((el) => ({ label: el.nationalityName(), value: el.value.id, key: el.value.id * 5 }));

    return [...promotedCountries, ...sortedValues];
  }

  translatedValues(): { [K in string]: string } {
    return transform(this.modelValues, (a, b) => (a[b.value.alpha_2_code?.toLowerCase() as string] = b.name || ''), {});
  }
}

export { Country, PROMOTE_COUNTRIES };
export default new Country();
export { SWEDEN_ID as SWEDEN_COUNTRY_ID, NORWAY_ID as NORWAY_COUNTRY_ID, GREECE_ID as GREECE_COUNTRY_ID };
