import { paymentByToken } from './paymentByToken';
import { paymentsByDistance } from './paymentsByDistance';

/**
 * @description
 * setTimeout infinite loops to keep data updating
 * TODO, should be removed when live data updates will be available
 */
export const loadingLoops = {
  /**
   * TODO,
   * remove when live data streaming will be available
   * @description
   * Infinite loop, to load current active payment
   * to track it's status changes
   */
  paymentByToken,
  /**
   * TODO,
   * remove when live data streaming will be available
   * @description
   * Infinite loop, to load registrations(payments) per distance
   * will be used to block payment if there is another payment in progress
   */
  paymentsByDistance,
};
