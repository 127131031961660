import { distanceSelector, raceSelector } from 'modules/registration/selectors/mappedData';
import { totalPrice } from 'modules/registration/selectors/prices/totalPrice';
import { transferService } from 'modules/registration/services';

import registrationStatus from 'stores/registrationStatus';

import { isPaymentFrameOpened, isPaymentSucceeded, isPaymentSuccessfullyCompleted } from '../../stores';

import { constructTransferRegistrationRequest } from '../../selectors/requests';
import { errorsActions } from '../errors';
import { loaderActions } from '../loader';
import { processPayment } from '../processPayment';
import { snackActions } from '../snacks';
import { executeProfileActions } from './profileActions';

export async function submitTransferRegistration() {
  if (!(await executeProfileActions())) {
    return;
  }

  const request = constructTransferRegistrationRequest();

  const distanceId = distanceSelector.id.get();
  const raceId = raceSelector.id.get();

  if (!distanceId || !raceId) {
    return;
  }

  const response = await transferService.submitTransferConfirmation(raceId, request);

  if (response.isOk) {
    if (!response.data?.value && totalPrice.get() === 0) {
      endTransferProcessPayment();
    } else {
      processPayment(
        response.data?.value!,
        response.data?.operations!,
        response.data?.registration?.uuid!,
        response.data?.payment?.uuid!,
        response.data?.service_fee ?? 0,
        response.data?.payment_method_order ?? [],
      );
    }
  }

  if (!response.isOk) {
    errorsActions.catchGroupRegistrantsErrors(response.errors);
    errorsActions.catchGroupRegistrationAdditionalFields(response.errors);
    errorsActions.catchGroupRegistrantCustomFieldErrors(response.errors);
    errorsActions.catchRegistrationToastErrors(response.message, response.errors);

    /**
     * reload distance and race to enable snacks error handling, see
     * reactions/observeConditionsForPermanentSnacks.ts
     * TODO, should be replaced with live data streaming
     */
    loaderActions.loadDistance();
    loaderActions.loadRace();
  }
}

export const endTransferProcessPayment = () => {
  registrationStatus.isRegisteredSuccessfully = true;
  isPaymentSuccessfullyCompleted.on();
  snackActions.payment.successSnack.show({ freeDistance: true });
  isPaymentSucceeded.clear();
  isPaymentFrameOpened.off();
};
