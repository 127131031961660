import { keys, isNil } from 'lodash';
import { distanceSelector } from 'modules/registration/selectors/mappedData';
import Xregexp from 'xregexp';

import { REGISTRATION_BE_ERROR_KEY_PATTERNS } from '../../constants';

import { fieldIdUtils } from '../../utils';

import { _addErrors } from './_addErrors';

/**
 * @description
 * Catch all errors for group registrants that related to custom fields
 * remap errors to the frontend format of fieldid
 *
 * reformat from
 *   "data.0.registration_fields_values.fields.1.values": [
 *     "The data.0.registration_fields_values.fields.1.values field is required."
 *    ]
 * to {[fieldIdUtils.groupRegistrationCustomField(...)]: ['The field is required.'] }
 *
 *
 *
 *
 * Also remove error service keys like 'group.0.registration_fields_values'
 */
function catchGroupRegistrantCustomFieldErrors(errors: RawError | nil) {
  if (!errors) {
    return;
  }

  const errorKeys = keys(errors);

  const mappedErrors = errorKeys.reduce((processedErrors, errorKey) => {
    const match = Xregexp.exec(errorKey, REGISTRATION_BE_ERROR_KEY_PATTERNS.groupRegistrationCustomFields());
    const distanceCustomFields = distanceSelector.customFields.get();

    if (!match) {
      return processedErrors;
    }

    const customFieldIndex = parseInt(match.customFieldIndex);
    const groupRegistrantIndex = parseInt(match.groupRegistrantIndex);

    if (isNil(customFieldIndex) || isNil(groupRegistrantIndex)) {
      return processedErrors;
    }

    const customField = distanceCustomFields.find((cf) => cf.id === customFieldIndex);

    if (!customField) {
      return processedErrors;
    }

    const newErrorKey = fieldIdUtils.groupRegistrationCustomField(groupRegistrantIndex, customField.id);

    const oldErrors = errors[errorKey] || [];
    const newErrors = oldErrors.map((error) =>
      Xregexp.replace(error, REGISTRATION_BE_ERROR_KEY_PATTERNS.groupRegistrationCustomFields(false), ''),
    );

    processedErrors[newErrorKey] = newErrors;

    return processedErrors;
  }, {});

  _addErrors(mappedErrors);
}

export { catchGroupRegistrantCustomFieldErrors };
