import { paymentCompleteActions } from 'modules/registration/actions/paymentCompleteActions';
import { RegistrationStatus, RegistrationStepEnum } from 'modules/registration/constants';
import { registrationService } from 'modules/registration/services';
import { currentPaymentStore, stepperStore } from 'modules/registration/stores';
import { parseUrlParams, preloaders } from 'modules/registration/utils';
import { registrationLeaderEmailExistsErrorUtil } from 'modules/registration/validations';

const MAX_POLL_COUNT = 5;
let pollCount = 0;

export const checkStripeRedirect = async () => {
  const { registrationComplete: uuid } = parseUrlParams();

  if (!uuid) {
    return;
  }
  const response = await registrationService.checkRegistrationStatus(uuid);

  if (response.isOk) {
    stepperStore.init(RegistrationStepEnum.confirmation);
    currentPaymentStore.setRegistrationStatus(response.data?.data?.status || null);
    if (response.data?.data?.status === RegistrationStatus.completed) {
      registrationLeaderEmailExistsErrorUtil.cleanError();
      paymentCompleteActions.success();
      preloaders.loadRegistrationStatus.disable();
    } else if (response.data?.data?.status === RegistrationStatus.canceled) {
      if (pollCount < MAX_POLL_COUNT) {
        //  Try again, to avoid the bug related with to the user's ability to refuse to pay by card and pay for iDeal
        pollCount += 1;
        setTimeout(checkStripeRedirect, 1000);
      } else {
        paymentCompleteActions.cancel();
        preloaders.loadRegistrationStatus.disable();
      }
    } else if (response.data?.data?.status === RegistrationStatus.pending) {
      setTimeout(checkStripeRedirect, 1000);
    }
  }
};
