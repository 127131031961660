import { sessionStore } from 'stores';

import { mainConditions } from '../../selectors/mainConditions';

function logoutIfConfirmation() {
  if (mainConditions.isConfirmation.get() && !mainConditions.isStripeRedirect()) {
    sessionStore.logout();
  }
}

export { logoutIfConfirmation };
