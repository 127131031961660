import { parseUrlParams } from '../../utils';

import { raceService } from '../../services';

import { raceStore } from '../../stores';

type Options = {
  setToStore: boolean;
};

/**
 * @description
 * Load race based on the url params
 */
async function loadRace(options: Options = { setToStore: true }) {
  const raceId = Number(parseUrlParams().raceId);

  if (!raceId) {
    return;
  }

  const race = await raceService.load(raceId);
  if (options.setToStore) {
    race && raceStore.setValue(race);
  } else {
    return race;
  }
}

export { loadRace };
