import { Observer } from 'mobx-react';
import { MyResultsTabContainer } from 'modules/profile/components/MyResults/MyResultsTabContainer';
import React, { FC } from 'react';

import { LOAD_COUNTRIES } from 'src/constants';
import { ItemType } from 'src/styledComponents/TagsFilter';

import { progressStore } from 'stores';

import { INITIALIZE_PROFILE, LOAD_MY_RESULTS } from '../../constants';

import { resultStore } from '../../stores';

export const MyResultsTab: FC = () => (
  <Observer>
    {() => {
      const countVR = resultStore.virtual.length;
      const countCumulative = resultStore.cumulative.length;
      const countClassic = resultStore.classic.length;
      const isLoading =
        progressStore.isLoading(LOAD_MY_RESULTS) || progressStore.isLoading(INITIALIZE_PROFILE) || progressStore.isLoading(LOAD_COUNTRIES);

      const getTabList = (): ItemType[] => {
        const list: ItemType[] = [];
        countClassic && list.push({ id: 1, label: 'classic', value: 'classic' });
        countVR && list.push({ id: 2, label: 'virtual', value: 'virtual' });
        countCumulative && list.push({ id: 3, label: 'cumulative', value: 'cumulative' });
        return list;
      };

      return (
        <MyResultsTabContainer
          tabList={getTabList()}
          countVR={countVR}
          countCumulative={countCumulative}
          countClassic={countClassic}
          isLoading={isLoading}
        />
      );
    }}
  </Observer>
);
