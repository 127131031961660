import { Observer } from 'mobx-react';
import { generateIsMenuOpen } from 'modules/profile/selections/actionMenu/display';
import React, { FC, useRef } from 'react';
import styled, { css } from 'styled-components';

import { DEFAULT_RACE_IMAGE, RESOLUTIONS } from 'src/constants';
import { RaceProfileCardProps } from 'src/styledComponents/Card';
import {
  Body,
  Content,
  ImageBox,
  InlineFlex,
  SportTag,
  StyledCard,
  TransferRegistrationInProgressBox,
} from 'src/styledComponents/Card/Components';
import { Popup } from 'src/styledComponents/Card/Popup';
import {
  DateUtility,
  Header,
  More,
  RaceDescription,
  ResultsRow,
  SportType,
  TitleUtility,
  RaceResultsLink,
  TruncatedUtility,
  MoreButtonDisabler,
} from 'src/styledComponents/Card/styled';
import { ShowDesktop, ShowMobile } from 'src/styledComponents/Conditions';
import { HorizontalScroll } from 'src/styledComponents/HorizontalScroll';
import { Svg } from 'src/styledComponents/Icons';
import { Skeleton } from 'src/styledComponents/Skeleton';
import { Headline, Utility } from 'src/styledComponents/Typography';

import { Hide, Show } from 'components/condition';

import { t } from 'utils';

import { windowSize } from 'stores';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${StyledCard} {
    min-width: 300px;
    margin-right: 32px;
  }

  ${DateUtility} {
    line-height: 16px;
  }

  ${MoreButtonDisabler} {
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
  }

  .my-races-content {
    overflow: hidden;
    width: 100%;
    padding: 0;

    .first-row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px 0;

      > div:first-child {
        padding: 0;
        padding-right: 16px;
      }
    }

    .second-row {
      display: flex;
      align-items: center;
      padding-top: 32px;
      padding-bottom: min(16px, max(12px, 1vh));

      .flex-row {
        display: flex;
        align-items: center;
        padding-right: 16px;
        min-height: fit-content;

        span:first-child {
          padding-right: 8px;
        }
      }
    }
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    ${TitleUtility} {
      min-height: 28px;
    }

    .body-title {
      padding: 0;
    }

    .my-races-content .second-row {
      flex-wrap: wrap;
    }
  }

  @media (max-width: ${RESOLUTIONS.medium}px) {
    flex-direction: column;
    padding: 0 20px;

    ${StyledCard} {
      width: 100%;
      margin: 0;
    }

    .body-title {
      margin: 0;
      padding-top: 24px;
    }

    .my-races-content .second-row {
      padding-top: 24px;

      .flex-row {
        white-space: nowrap;
      }
    }
  }
`;

const TitleHeadline = styled(Headline)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  width: calc(100% - 40px);
`;

const TwoLinesUtility = styled(Utility)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const StyledMore = styled(More)`
  position: absolute;
  right: 0;
  top: 0;
  padding: 4px;

  ${(props) =>
    props.isOpen &&
    css`
      background: ${props.theme.main.colors.clay5};

      path {
        fill: ${props.theme.main.colors.clay3};
      }
    `}
`;

const StyledInlineFlex = styled(InlineFlex)<{ size: number }>`
  > div {
    min-width: ${(props) => props.size}px;
    min-height: ${(props) => props.size}px;
  }
`;

type Props = RaceProfileCardProps & {
  bib?: string | number;
  racerClass?: string;
  wave?: string;
  isTransferRegistrationInProgress: boolean;
};

export const MyRacesCard: FC<Props> = ({
  bodyTitle,
  className,
  distanceId,
  distanceLength,
  distanceName,
  image,
  location,
  onClickMore,
  published,
  raceResultsLink,
  settings,
  sportIcon,
  sportType,
  bib,
  racerClass,
  wave,
  startDate,
  endDate,
  isTransferRegistrationInProgress,
}) => {
  const isOpenActions = generateIsMenuOpen(distanceId);

  const ref = useRef(null);

  const getDate = () => {
    if (startDate && endDate) {
      return startDate === endDate ? startDate : `${startDate} - ${endDate}`;
    }

    return startDate ? startDate : '';
  };

  const getAnchorStyles = (anchorRef) => {
    const refProps = anchorRef?.getBoundingClientRect();
    const offsetRight = window.innerWidth - refProps?.right - 52;
    const offsetTop = window.pageYOffset + refProps?.top + 8;

    return {
      top: offsetTop,
      left: 'unset',
      right: offsetRight,
    };
  };

  return (
    <Observer>
      {() => {
        const isMobile = windowSize.isLessThan('medium');
        const isOpen = isOpenActions.get();

        return (
          <Wrapper className={className}>
            <StyledCard>
              <Header>
                <div>
                  <Svg name='DateCalendar' size={16} />
                  <DateUtility variant='u4' weight='bold'>
                    {getDate()}
                  </DateUtility>
                </div>
                <ShowMobile>
                  <div>
                    <More name='More' size={24} isOpen={isOpen} onClick={onClickMore} />
                  </div>
                </ShowMobile>
              </Header>

              <Body>
                <ImageBox bg={image || DEFAULT_RACE_IMAGE} />
                <Show if={isTransferRegistrationInProgress}>
                  <TransferRegistrationInProgressBox isMobile={isMobile}>
                    <Utility variant='u3' weight='medium' className='transfer-text'>
                      {t.staticAsString('transferRegistration.inProgress')}
                    </Utility>
                  </TransferRegistrationInProgressBox>
                </Show>

                <SportTag>
                  <img src={sportIcon} alt='' />
                  <SportType>{sportType}</SportType>
                </SportTag>
              </Body>
            </StyledCard>

            <Content className='my-races-content'>
              <TitleHeadline variant={isMobile ? 'h3' : 'h4'} className='body-title'>
                <Show if={Boolean(raceResultsLink)}>
                  <RaceResultsLink to={raceResultsLink as string} condition={Boolean(published)} ATN={bodyTitle}>
                    {bodyTitle}
                  </RaceResultsLink>

                  <ShowDesktop>
                    <div ref={ref}>
                      <StyledMore name='More' size={32} isOpen={isOpen} onClick={onClickMore} />
                    </div>
                  </ShowDesktop>

                  <Show if={isOpen}>
                    <MoreButtonDisabler />
                    <Popup items={settings} handleClose={onClickMore} withPortal portalStyles={getAnchorStyles(ref?.current)} />
                  </Show>
                </Show>
                <Hide if={Boolean(raceResultsLink)}>{bodyTitle}</Hide>
              </TitleHeadline>

              <RaceDescription>
                <InlineFlex>
                  <TwoLinesUtility variant={isMobile ? 'u2' : 'u3'} weight='medium' title={distanceName}>
                    {distanceName}
                  </TwoLinesUtility>
                </InlineFlex>

                <div className='first-row'>
                  <StyledInlineFlex size={isMobile ? 16 : 24}>
                    <Svg name='Distance' size={isMobile ? 16 : 24} />
                    <TruncatedUtility variant='u4' weight='medium' title={distanceName}>
                      {distanceLength}
                    </TruncatedUtility>
                  </StyledInlineFlex>

                  <Show if={Boolean(location)}>
                    <StyledInlineFlex size={isMobile ? 16 : 24}>
                      <Svg name='Location' size={isMobile ? 16 : 24} />
                      <TruncatedUtility variant={isMobile ? 'u2' : 'u3'} weight='medium' title={location}>
                        {location}
                      </TruncatedUtility>
                    </StyledInlineFlex>
                  </Show>
                </div>

                <Show if={Boolean(bib || racerClass || wave)}>
                  <HorizontalScroll>
                    <div className='second-row'>
                      <Show if={Boolean(bib)}>
                        <div className='flex-row'>
                          <Utility variant={isMobile ? 'u2' : 'u3'} weight='bold'>
                            {t.staticAsString('profile.myRaces.bib')}:
                          </Utility>
                          <Utility variant={isMobile ? 'u2' : 'u3'} weight='medium'>
                            {bib}
                          </Utility>
                        </div>
                      </Show>

                      <Show if={Boolean(racerClass)}>
                        <div className='flex-row'>
                          <Utility variant={isMobile ? 'u2' : 'u3'} weight='bold'>
                            {t.staticAsString('profile.myRaces.class')}:
                          </Utility>
                          <Utility variant={isMobile ? 'u2' : 'u3'} weight='medium'>
                            {racerClass}
                          </Utility>
                        </div>
                      </Show>

                      <Show if={Boolean(wave)}>
                        <div className='flex-row'>
                          <Utility variant={isMobile ? 'u2' : 'u3'} weight='bold'>
                            {t.staticAsString('profile.myRaces.wave')}:
                          </Utility>
                          <Utility variant={isMobile ? 'u2' : 'u3'} weight='medium'>
                            {wave}
                          </Utility>
                        </div>
                      </Show>
                    </div>
                  </HorizontalScroll>
                </Show>
              </RaceDescription>
            </Content>
          </Wrapper>
        );
      }}
    </Observer>
  );
};

const SkeletonWrapper = styled.div`
  display: flex;
  align-items: center;

  .fake-img {
    margin-right: 48px;
    min-width: 300px;
  }

  .row {
    padding: 0;
    padding-top: 16px;
  }

  .content {
    width: 100%;
    padding: 0;
  }

  .row:first-child {
    padding-top: 0;
  }

  @media (max-width: ${RESOLUTIONS.medium}px) {
    flex-direction: column;
    padding: 0 20px;

    .fake-img {
      margin: 0;
      min-width: 100%;
    }

    .row:first-child {
      padding-top: 24px;
    }
    .row:last-child {
      padding-top: 8px;
    }
  }
`;

export const MyRacesCardSkeleton: FC = () => {
  const isMobile = windowSize.isLessThan('medium');
  const height = isMobile ? ((window.innerWidth - 44) / 16) * 9 + 48 : 210;
  const width = isMobile ? '100%' : '300px';
  return (
    <SkeletonWrapper>
      <Skeleton variant='rectangular' height={height} width={width} className='fake-img' />
      <Content className='content'>
        <ResultsRow className='row'>
          <Skeleton height={28} width={300} />
          <ShowDesktop>
            <Skeleton height={24} width={24} variant='rectangular' />
          </ShowDesktop>
        </ResultsRow>

        <Skeleton height={20} width='55%' />

        <ResultsRow className='row' style={{ width: '240px' }}>
          <Skeleton height={18} width={60} />
          <Skeleton height={18} width={164} />
        </ResultsRow>

        <ResultsRow className='row' style={{ width: '268px' }}>
          <div>
            <Skeleton height={18} width={60} />
          </div>

          <div>
            <Skeleton height={18} width={90} />
          </div>

          <div>
            <Skeleton height={18} width={85} />
          </div>
        </ResultsRow>
      </Content>
    </SkeletonWrapper>
  );
};
