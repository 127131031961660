import * as React from 'react';
import styled from 'styled-components';

import { PropsMobile } from 'src/modules/tourProfileManagment/components/Header/styled';
import { ShowDesktop, ShowMobile } from 'src/styledComponents/Conditions';
import { Utility } from 'src/styledComponents/Typography';

import { htmlSanitizer } from 'utils';

import { windowSize } from 'stores';

type Props = {
  raceLength: string | null;
  raceLengthMobile: Array<string>;
  raceName: string;
};

const Wrapper = styled.div<PropsMobile>`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;

  .clay1 {
    color: ${(props) => props.theme.main.colors.clay1};
  }
  .raceLength {
    margin-right: 16px;
  }

  ${(props) => props.isMobile && WrapperMobileStyles}
`;

const WrapperMobileStyles = `
  align-items: center; 
  margin: 0;
  padding: 0 16px 0 0;   
  flex: 1;
  
  .km {
    font-weight: 500;
  }  
`;

const MobileWrapper = styled.div`
  text-align: center;
`;

export const DistanceTopArea = (props: Props) => {
  const { raceLength, raceName, raceLengthMobile } = props;
  const isMobile = windowSize.isLessThan('medium');

  return (
    <Wrapper isMobile={isMobile}>
      <ShowDesktop>
        {raceLength && (
          <Utility weight='bold' variant='u3' className={raceLength ? 'clay1 raceLength' : ''}>
            <span dangerouslySetInnerHTML={{ __html: htmlSanitizer(raceLength) }} />
          </Utility>
        )}
        <Utility weight='medium' variant='u3' className='clay1 km'>
          {raceName}
        </Utility>
      </ShowDesktop>

      <ShowMobile>
        {raceLength && (
          <MobileWrapper>
            <Utility weight='bold' variant='u1' className={raceLength ? 'clay1 raceLength' : ''}>
              <span>{raceLengthMobile && raceLengthMobile[0]}</span>
            </Utility>

            <br />

            <Utility weight='medium' variant='u3' className={raceLength ? 'clay1 raceLength' : ''}>
              <span>{raceLengthMobile && raceLengthMobile[1]}</span>
            </Utility>
          </MobileWrapper>
        )}

        <Utility weight='medium' variant='u2' className='clay1'>
          {raceName}
        </Utility>
      </ShowMobile>
    </Wrapper>
  );
};
