import { computed } from 'mobx';

import { giftcardStore } from '../../stores';

const giftcardErrors = computed(() => {
  const generalErrors = giftcardStore.invalidValues;
  return generalErrors;
});

export { giftcardErrors };
