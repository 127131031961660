import { computed } from 'mobx';
import shortid from 'shortid';

import { getAllCouponFields } from './_getAllCouponFields';
import { couponErrors } from './couponErrors';

/**
 * @description
 * Generate observable computed variable
 * that will trigger change in case
 * if
 *  - new errors added
 *  - or order of the changed field has been updated
 *
 * return value - string, 'AS2KJDH' (shortid)
 */
function couponErrorReactionTrigger() {
  const couponChanges = computed(() => {
    const fields = getAllCouponFields();
    const errors = couponErrors.get();
    return { fields, errors };
  });

  return computed(() => {
    couponChanges.get();
    return shortid();
  }).get();
}

export { couponErrorReactionTrigger };
