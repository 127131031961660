import { t, fireToast } from 'utils';

function generalValidation() {
  fireToast(
    t.staticAsString('registration.toast.generalValidation'),
    'error',
    t.staticAsString('registration.toast.validationError'),
    'top-right',
  );
}

function invalidTransferLink() {
  fireToast(
    t.staticAsString('registration.invalidRequestToken'),
    'warning',
    t.staticAsString('registration.toast.validationError'),
    'top-right',
  );
}

function invalidTransferAnymoreLink() {
  fireToast(
    t.staticAsString('registration.invalidRequestTokenAnymore'),
    'warning',
    t.staticAsString('registration.toast.validationError'),
    'top-right',
  );
}

export const toastUtils = {
  generalValidation,
  invalidTransferLink,
  invalidTransferAnymoreLink,
};
