import { keys } from 'lodash';

import { errorsStore } from 'stores';

import { REGISTRATION_FORM } from '../../constants';

import { couponErrors } from '../../selectors/coupons/couponErrors';

/**
 * @description
 * Collect all invalid coupons in the form
 * and add those errors to the errorsStore(connect to the form)
 */
function addCouponErrors() {
  const errors = couponErrors.get() as {};
  errorsStore.clean(REGISTRATION_FORM, ...keys(errors));
  errorsStore.add(REGISTRATION_FORM, errors);
}

export { addCouponErrors };
