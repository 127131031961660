// export * from './processRegistrationErrors';
import { catchGroupRegistrantsErrors } from './catchGroupRegistrantsErrors';
import { catchGroupRegistrationAdditionalFields } from './catchGroupRegistrationAdditionalFields';
import { catchGroupRegistrantCustomFieldErrors } from './catchGroupRegistrationCustomFieldErrors';
import { catchProfileRegistrationErrors } from './catchProfileRegistrationErrors';
import { catchProfileUpdateErrors } from './catchProfileUpdateErrors';
import { catchRegistrationToastErrors } from './catchRegistrationToastErrors';
import { catchTeamMembersCustomFieldErrors } from './catchTeamMembersCustomFieldErrors';
import { catchTeamMembersErrors } from './catchTeamMembersErrors';
import { catchTeamRegistrationErrors } from './catchTeamRegistrationErrors';

const errorsActions = {
  /**
   * @description
   * Catch all errors for group registrants that related to registration fields and additional fields
   * constants.RegistrationFields and constants.AdditionalRegistrationFields
   *
   * remap errors to the frontend format of fieldid
   *
   * Also remove error service keys like 'group.0.registration_fields_values'
   *
   * Custom fields not included
   */
  catchGroupRegistrantsErrors,

  /**
   * @description
   * Catch errors related to the team registration but not to the registration fields of registration leader or team members
   * team_name, class_id, wave_id
   */
  catchGroupRegistrationAdditionalFields,

  /**
   * @description
   * Remap and add profile registration errors
   * email,password, password_confirmation
   */
  catchProfileRegistrationErrors,
  /**
   * @description
   * Remap and add profile update errors
   * almost all registration fields
   */
  catchProfileUpdateErrors,

  /**
   * @description
   * Catch errors that cannot be applied to the form and should be displayed in the toast
   */
  catchRegistrationToastErrors,

  /**
   * @description
   * Catch all errors for group registrants that related to custom fields
   * remap errors to the frontend format of fieldid
   *
   * reformat from
   *   "data.0.registration_fields_values.fields.1.values": [
   *     "The data.0.registration_fields_values.fields.1.values field is required."
   *    ]
   * to {[fieldIdUtils.groupRegistrationCustomField(...)]: ['The field is required.'] }
   *
   *
   *
   *
   * Also remove error service keys like 'group.0.registration_fields_values'
   */
  catchGroupRegistrantCustomFieldErrors,

  /**
   * @description
   * Remap and add to errorsStore, BE errors to frontend errors, specifically:
   * ALL team registration members, registration fields errors
   * like second members phone number or email etc...
   *
   * Also remove error service keys like 'group.0.team_members.0.email' and leave only 'email
   *
   * Custom fields not included
   */
  catchTeamMembersErrors,

  /**
   * @description
   * Catch all errors for team members that related to custom fields
   * remap errors to the frontend format of fieldid
   *
   * reformat from
   *   "data.0.team_members.0.fields.1.values": [
   *     "The data.0.team_members.0.fields.1.values field is required."
   *    ]
   * to {[fieldIdUtils.teamMembersCustomField(...)]: ['The field is required.'] }
   *
   *
   *
   *
   * Also remove error service keys like 'group.0.registration_fields_values'
   */
  catchTeamMembersCustomFieldErrors,

  /**
   * @description
   * Catch errors related to the team registration but not to the registration fields of registration leader or team members
   * team_name, class_id, wave_id
   */
  catchTeamRegistrationErrors,
};

export { errorsActions };
