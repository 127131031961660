import { generatePath } from 'react-router-dom';

import { ROUTER } from 'src/constants';

import { history } from 'utils';

import { raceSelector } from '../selectors/mappedData';

function redirectToAbout() {
  const id = raceSelector.id.get();

  if (!id) return;

  const pathname = generatePath(ROUTER.RACE_ABOUT_US, { id });

  return history.push({
    pathname,
  });
}

export { redirectToAbout };
