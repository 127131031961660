import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';

import { BE_DATE_FORMAT } from 'src/constants';
import { mapRaces } from 'src/modules/home/utils';
import { sportTypesStore } from 'src/stores';

import distance from 'stores/distance';

import { GeoPoint } from './../types';

const filters = {
  raceName: 'term',
  location: 'location',
  date: '',
  distance: 'length_to',
  sports: 'sports',
  only_internal_registration: 'only_internal_registration',
};

export const mapFilters = (data: AnyObject) => {
  return Object.keys(filters).reduce((result: AnyObject, current: string) => {
    return {
      ...result,
      ...cases(current, data[current]),
    };
  }, {});
};

const cases = (name: string, data: any) => {
  if (!data) return {};

  switch (name) {
    case 'date':
      if (!data.startDate || !data.endDate) return {};

      return {
        date_from: data.startDate.format(BE_DATE_FORMAT),
        date_to: data.endDate.format(BE_DATE_FORMAT),
      };

    case 'sports':
      if (isEmpty(data)) return {};

      return { [filters[name]]: data.map((item) => item.key) };

    case 'distance':
      if (isEmpty(data)) return {};

      return {
        length: data.map((item) => ({
          length_from: item.value[0] * 1000,
          length_to: item.value[1] * 1000,
        })),
      };

    case 'raceName':
    case 'location':
    case 'only_internal_registration':
      return { [filters[name]]: data };
  }
};

export const mapPoints = (data: AnyObject[], isDateFilter: boolean): GeoPoint[] => {
  const copy = cloneDeep(data);

  const mapped = copy.map((race) => {
    const sport = sportTypesStore.valuesById[race.sport_id];

    return {
      id: race.id,
      type: 'Feature',
      properties: {
        cluster: false,
        id: race.id,
        lat: race.route.lat,
        lng: race.route.lng,
        sport_id: race.sport_id,
        sportIcon: sport?.icon || '',
        isPast: moment().isAfter(race.race_end_date),
      },
      geometry: {
        type: 'Point',
        coordinates: [race.route.lng, race.route.lat],
      },
    };
  });

  if (!isDateFilter) return mapped.filter((item) => !item.properties.isPast) as GeoPoint[];

  return mapped as GeoPoint[];
};

export const mapRace = (data: AnyObject) => {
  return mapRaces([data])[0];
};
