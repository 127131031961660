import { isArray } from 'lodash';

import { form, progressStore } from 'stores';

import { COUPON_LOADING, REGISTRATION_FORM } from '../../constants';

import { couponCodeService } from '../../services';

import { couponsStore } from '../../stores';

import { distanceSelector } from '../../selectors/mappedData';

/**
 * @description
 * Check is current coupon code is valid and init it if it is
 * if no mark it as invalid
 * if yes load coupon model and set it to the store
 */
function initCouponCode(fieldId: string) {
  return async () => {
    const code = form.fetch<string | nil>(REGISTRATION_FORM, fieldId);
    const distanceId = distanceSelector.id.get();

    if (!distanceId || !code) {
      return null;
    }

    progressStore.log(COUPON_LOADING, 'progress');
    const couponData = await couponCodeService.load(distanceId, code);
    progressStore.log(COUPON_LOADING, 'completed');

    if (isArray(couponData)) {
      couponsStore.initInvalid(fieldId, couponData);
    } else {
      couponsStore.init(fieldId, couponData);
    }
  };
}

export { initCouponCode };
