import { smartLinkService } from 'modules/registration/services';
import { parseUrlParams } from 'modules/registration/utils';

import { smartLinkStore } from '../stores';

export const checkIfSmartLinkValid = async () => {
  const { smartLinkToken } = parseUrlParams();
  if (!smartLinkToken) {
    return;
  }
  const data = await smartLinkService.checkSmartLink(smartLinkToken);
  data && smartLinkStore.setValue(data);
};
