import { form } from 'stores';

import { REGISTRATION_FORM } from '../../constants';

import { getAllCouponFields } from './_getAllCouponFields';

/**
 * @description
 * Coupon codes fetched from store.form
 * { [leader.coupon_code]: 'SGASD', [group.3.coupon_code]: 'SOME_CODE' }
 */
function fetchCouponFormValues() {
  return getAllCouponFields().reduce(
    (acc, couponFieldId) => ({
      ...acc,
      [couponFieldId]: form.fetch<string | nil>(REGISTRATION_FORM, couponFieldId),
    }),
    {},
  );
}

export { fetchCouponFormValues };
