import { PaymentMethod } from 'modules/registration/constants';
import { formDataSelectors } from 'modules/registration/selectors/formDataSelectors';

import { paymentTrackerService } from '../../services';

import { disposers, currentPaymentStore } from '../../stores';

const TIMEOUT_DELAY = 4000;

/**
 * TODO,
 * remove when live data streaming will be available
 * @description
 * Infinite loop, to load current active payment
 * to track it's status changes
 */
export function paymentByToken() {
  let isLoopActive = true;
  let timeoutId: any;

  const disposer = () => {
    isLoopActive = false;
    timeoutId && clearTimeout(timeoutId);
  };

  const iteration = () => {
    timeoutId = setTimeout(async () => {
      if (!isLoopActive || formDataSelectors.fetchLeaderPaymentMethod() === PaymentMethod.creditCard) {
        return;
      }

      const token = currentPaymentStore.token;

      if (!token) {
        iteration();
        return;
      }

      await paymentTrackerService.loadPaymentByToken(token);

      if (isLoopActive) {
        iteration();
      }
    }, TIMEOUT_DELAY);
  };

  iteration();
  disposers.register(disposer);
}
