import axios from 'axios';
import shortid from 'shortid';

import { API_URL, API_USER_PROFILE_URL } from 'src/constants';

import { request, action } from 'utils';

import { Data } from '../types';

class UpdateProfileService {
  @request({ action: shortid() })
  updateRequest(updateData: Data.Backend.Racer): Promise<any> {
    const url = API_URL + API_USER_PROFILE_URL;

    const options = {
      url,
      method: 'PATCH',
      data: updateData,
    };

    return axios(options as any);
  }

  @action({ action: shortid() })
  async update(data: Data.Backend.Racer): Promise<{ isOk: boolean; errors: RawError | nil }> {
    const [isOk, response] = await this.updateRequest(data);

    return {
      isOk,
      errors: response.data.errors,
    };
  }
}

export { UpdateProfileService };
