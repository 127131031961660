import { generatePath } from 'react-router-dom';

import { ROUTER } from 'src/constants';

import { history } from 'utils';

import { mapperUtils, parseUrlParams } from '../utils';

import { mainConditions } from '../selectors/mainConditions';
import { raceSelector } from '../selectors/mappedData';

/**
 * @description
 * Redirect to not found page if:
 * - distance is missing from the loaded list of distances
 *    and id provided (when cannot load distance)
 * - race is not published
 */
function checkForNotFoundPage(): boolean {
  const race = raceSelector.model.get();
  const { distanceId } = parseUrlParams();

  if (!race) {
    history.replace(ROUTER.NOT_FOUND_PAGE);
    return true;
  }

  if (mainConditions.isDistanceSelectorMode()) {
    const path = generatePath(ROUTER.RACE_ABOUT_US, {
      id: race.value.id,
    });
    history.replace(path);
    return false;
  }

  const distance = mapperUtils.distanceIdMapper.mapFrom(Number(distanceId) || null);

  if (!distance) {
    history.replace(ROUTER.NOT_FOUND_PAGE);
    return true;
  }

  return false;
}

export { checkForNotFoundPage };
