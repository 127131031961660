import { snackStore } from 'stores';

let snackId: void | string;

function show() {
  clean();

  const snackParams = {
    i18key: 'registration.messages.offline',
  };

  snackId = snackStore.warn(snackParams);
}

function clean() {
  snackId && snackStore.removeValue(snackId);
}

const offlineSnack = {
  show,
  clean,
};

export { offlineSnack };
