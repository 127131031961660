import { errorsStore } from 'stores';

import { REGISTRATION_FORM } from '../constants';

import { fieldIdUtils } from '../utils';

export const registrationLeaderEmailExistsErrorUtil = {
  /**
   * @description
   * Add errors for team leader that email already taken
   */
  addError: () => {
    errorsStore.add(REGISTRATION_FORM, { [fieldIdUtils.registrationLeaderEmail()]: ['validation.emailAlreadyInDistance'] });
  },

  /**
   * @description
   * Clean error for team leader that email already taken
   */
  cleanError: () => {
    errorsStore.clean(REGISTRATION_FORM, fieldIdUtils.registrationLeaderEmail());
  },
};
