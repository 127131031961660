import { keys } from 'lodash';
import { AdditionalRegistrationFields, RegistrationFields } from 'modules/registration/constants';
import { Data } from 'modules/registration/types/data';
import moment from 'moment';

import { BACKEND_DATE_FORMAT } from 'src/constants';

/**
 * @description
 * Map data from the form to the format that can be sent to backend
 */
function mapFormRacerToBERacer(data: Data.Form.Racer): Data.Backend.Racer {
  const dataCols = keys(data);

  return dataCols.reduce<any>((mappedData: Data.Backend.Racer, col: RegistrationFields) => {
    const value = data[col];

    mappedData[col] = mapField(col, value);

    return mappedData;
  }, {});
}

function mapField(name: RegistrationFields | AdditionalRegistrationFields, value: any): any {
  switch (name) {
    case RegistrationFields.birthday:
      const date = moment(value || NaN);
      if (date.isValid()) {
        return date.format(BACKEND_DATE_FORMAT);
      } else {
        return null;
      }

    case RegistrationFields.shirt_size:
    case RegistrationFields.country_id:
    case RegistrationFields.nationality_id:
      return value?.value ?? null;
    case RegistrationFields.telephone:
      return value?.phone ?? null;
    case RegistrationFields.gender:
      return value?.value ?? null;
    case RegistrationFields.emergency_phone:
      return value?.phone ?? null;
    case AdditionalRegistrationFields.wave_id:
      return parseInt(value?.value) || null;
    case AdditionalRegistrationFields.class_id:
      return parseInt(value?.value) || null;
    case AdditionalRegistrationFields.discipline_id:
      return parseInt(value?.value) || null;
    case RegistrationFields.squad_id:
      return value?.value ?? null;

    default:
      return value ?? null;
  }
}

export { mapFormRacerToBERacer };
