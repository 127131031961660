import { snackStore } from 'stores';

import { SNACK_SCOPES } from '../../../constants';

let snackId: void | string;

function show() {
  clean();

  const snackParams = {
    i18key: 'registration.payment.cannotCancel',
    payload: { closeButton: true },
  };

  snackId = snackStore.warn(snackParams, { scope: SNACK_SCOPES.payment });
}

function clean() {
  snackId && snackStore.removeValue(snackId);
}

const cannotCancelSnack = {
  show,
  clean,
};

export { cannotCancelSnack };
