import { keys } from 'lodash';
import { squadsStore } from 'modules/registration/stores';

import { generateGenderOptions, phoneMapper } from 'utils';

import { ClassModel } from 'models';

import { countriesStore } from 'stores';

import { RegistrationFields, AdditionalRegistrationFields } from '../../constants';

import { generateShirtOptions } from '../../../../utils/generateShirtOptions';

import { selectOptions } from '../../selectors/selectOptions';
import { Data } from '../../types/data';

/**
 * @description
 * Map user data to format that can be inserted to the form
 * As input UserType (from the session can be used)
 * Or an object with registration fields
 *
 * Only registration fields will be mapped
 */
function mapBERacerToFormRacer(data: Partial<{ [k in RegistrationFields]: any }>): Data.Form.Racer {
  const fieldKeys = keys(data);
  return fieldKeys.reduce((mappedData, field) => {
    return { ...mappedData, [field]: mapFieldTo(field, data[field]) };
  }, {});
}

function mapFieldTo(name: string, value: any): any {
  switch (name) {
    case RegistrationFields.country_id:
      return countriesStore.valuesForNationalityDropdown().find((country) => country.value === value);
    case RegistrationFields.nationality_id:
      return countriesStore.valuesForDropdown().find((country) => country.value === value);
    case RegistrationFields.telephone:
      return phoneMapper(value);
    case RegistrationFields.emergency_phone:
      return phoneMapper(value);
    case RegistrationFields.gender:
      return generateGenderOptions().find((option) => value === option.value);
    case RegistrationFields.shirt_size:
      return generateShirtOptions().find((option) => value === option.value);

    case RegistrationFields.squad_id:
      return squadsStore.value?.find((option: NewSelectItemType) => value === option.value) || null;

    case AdditionalRegistrationFields.class_id:
      const classes = selectOptions.classes.get();
      return classes.find((option: NewSelectItemType) => ClassModel.compareSelectItem(value, option)) || null;

    case AdditionalRegistrationFields.wave_id:
      const waves = selectOptions.waves.get();
      return waves.find((option: NewSelectItemType) => option.value === value) || null;

    case AdditionalRegistrationFields.discipline_id:
      const disciplines = selectOptions.disciplines.get();
      return disciplines.find((option: NewSelectItemType) => option.value === value) || null;

    default:
      return value;
  }
}

export { mapBERacerToFormRacer };
