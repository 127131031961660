import { merge, thru, chain } from 'lodash';
import { fieldIdUtils, mapperUtils } from 'modules/registration/utils';

import { LOCAL_STORAGE_KEYS } from '../../../constants/localStorage-keys';
import { AdditionalRegistrationFields, PaymentMethod, FIELD_ID_PATTERNS } from '../constants';

import {
  couponsStore,
  disabledCustomFieldsStore,
  confirmationStore,
  transferStore,
  smartLinkStore,
  distanceProductStore,
  isPayOnSiteSelectedStore,
} from '../stores';

import { distanceSelector } from '../selectors/mappedData';
import { Request, Data } from '../types';
import { fieldConditions } from './fieldConditions';
import { formDataSelectors } from './formDataSelectors';
import { mainConditions } from './mainConditions';

/**
 * @description
 * Construct registration request for the single group registrations
 */
function constructGroupRegistrationsRequest(): Request.Registration.SingleGroup {
  const groupMembers = formDataSelectors.fetchGroupRegistrationMembers().map((groupMember, index) => {
    const registrationFields: Data.Backend.GroupRegistrationToSent = chain(groupMember)
      .thru(mapperUtils.selectFormRacerByRegistrationFields(distanceSelector.registrationFields.get()))
      .thru(mapperUtils.omitCoupon)
      .thru(mapperUtils.filterFormRacerNonExistingCoupon(couponsStore, index))
      .thru(mapperUtils.mapFormRacerToBERacer)
      .thru(mapperUtils.filterBERacerEmptyData)
      .value();

    const coupon = couponsStore.findByFieldId(`groupRegistrations.${index}.coupon_code`)?.code;

    const additionalFields: Data.Backend.RacerAdditionalFields = chain(groupMember)
      .thru(mapperUtils.filterFormRacerNonExistingCoupon(couponsStore, index))
      .thru(mapperUtils.selectRacerFormAdditionalFields(fieldConditions.racerAdditionalFields()))
      .thru(mapperUtils.mapFormRacerToBERacer)
      .thru(mapperUtils.filterBERacerEmptyData)
      .value();

    const customFields = chain(formDataSelectors.fetchGroupRegistrationCustomFields(index)).thru(mapperUtils.mapFormCFToBECF).value();

    const registrationsData = {
      distance_id: distanceSelector.id.get(),
      coupon_code: coupon,
      ...additionalFields,

      member: merge(registrationFields, {
        [fieldIdUtils.staticKeys.customFieldsKey]: customFields,
      }) as Data.Backend.GroupRegistrationToSent,
    };

    return registrationsData;
  });

  const request: Request.Registration.SingleGroup = {
    payment_method: PaymentMethod.creditCard,
    registrations: groupMembers,
    products: [],
  };

  if (formDataSelectors.fetchLeaderPaymentMethod()) {
    request.payment_method = formDataSelectors.fetchLeaderPaymentMethod()!;
  }

  if (distanceSelector.isRefundProtectEnabled.get()) {
    request.refund_protect = !!formDataSelectors.fetchLeaderRefundProtect();
  }

  if (mainConditions.isSmartLinkValid()) {
    request.magic_link = smartLinkStore?.value?.token;
  }

  if (mainConditions.isCampaignLinkExists()) {
    request.campaign_link = localStorage.getItem(LOCAL_STORAGE_KEYS.campaignLinkToken);
  }

  if (mainConditions.isProductDistance) {
    request.products =
      distanceProductStore.value?.map((product) => ({
        id: product.id,
        size: product.size,
        count: product.quantity,
      })) ?? [];
  }

  if (mainConditions.isGiftcardEnabled.get()) {
    request.giftcard = formDataSelectors.fetchGiftcard();
  }

  if (isPayOnSiteSelectedStore.value) {
    request[AdditionalRegistrationFields.pay_onsite] = true;
  }

  return request;
}

/**
 * @description
 * Construct registration request for the team registration
 */
function constructTeamRegistrationRequest(): Request.Registration.Team {
  const data = (function () {
    const _leaderAllFields = formDataSelectors.fetchRegistrationLeader();

    const leaderRegistrationFields: Data.Backend.GroupRegistrationToSent = chain(_leaderAllFields)
      .thru(mapperUtils.selectFormRacerByRegistrationFields(distanceSelector.registrationFields.get()))
      .thru(mapperUtils.omitCoupon)
      .thru(mapperUtils.filterFormRacerNonExistingCoupon(couponsStore, 0))
      .thru(mapperUtils.mapFormRacerToBERacer)
      .thru(mapperUtils.filterBERacerEmptyData)
      .value();

    const leaderAdditionalFields: Data.Backend.RacerAdditionalFields = chain(_leaderAllFields)
      .thru(mapperUtils.selectRacerFormAdditionalFields(fieldConditions.racerAdditionalFields()))
      .thru(mapperUtils.mapFormRacerToBERacer)
      .thru(mapperUtils.filterBERacerEmptyData)
      .value();

    const classId = formDataSelectors.fetchTeamClassId();
    const waveId = formDataSelectors.fetchTeamWaveId();

    const teamAdditionalFields: Data.Backend.RacerAdditionalFields = chain({
      [AdditionalRegistrationFields.class_id]: classId,
      [AdditionalRegistrationFields.wave_id]: waveId,
    } as Data.Backend.RacerToReceive)
      .thru(mapperUtils.filterBERacerEmptyData)
      .value();

    const leaderCustomFields = chain(formDataSelectors.fetchLeaderCustomFields()).thru(mapperUtils.mapFormCFToBECF).value();

    const teamMembers = formDataSelectors.fetchTeamRegistrationMembers().map((teamMember, index) => {
      const registrationFields: Data.Backend.GroupRegistrationToSent = chain(teamMember)
        .thru(mapperUtils.selectFormRacerByRegistrationFields(distanceSelector.registrationFields.get()))
        .thru(mapperUtils.mapFormRacerToBERacer)
        .thru(mapperUtils.filterBERacerEmptyData)
        .value();

      const additionalFields: Data.Backend.RacerAdditionalFields = chain(teamMember)
        .thru(mapperUtils.selectRacerFormAdditionalFields(fieldConditions.racerAdditionalFields()))
        .thru(mapperUtils.mapFormRacerToBERacer)
        .thru(mapperUtils.filterBERacerEmptyData)
        .value();

      const customFields = chain(formDataSelectors.fetchTeamRegistrationMemberCustomFields(index))
        .thru(mapperUtils.mapFormCFToBECF)
        .value();

      return merge(registrationFields, additionalFields, { [fieldIdUtils.staticKeys.customFieldsKey]: customFields });
    });

    const leader = merge(leaderRegistrationFields, leaderAdditionalFields, {
      [fieldIdUtils.staticKeys.customFieldsKey]: leaderCustomFields,
    });
    const teamName = formDataSelectors.fetchTeamTeamName();

    const coupon = couponsStore.findByFieldId(`groupRegistrations.0.coupon_code`)?.code;

    const registrationObject = {
      ...teamAdditionalFields,
      distance_id: distanceSelector.id.get(),
      coupon_code: coupon,

      team: {
        name: teamName,
        members: [leader, ...teamMembers],
      },
    };

    return registrationObject;
  })();

  const request: Request.Registration.Team = {
    payment_method: PaymentMethod.creditCard,
    registrations: [data],
  };

  if (distanceSelector.isRefundProtectEnabled.get()) {
    request.refund_protect = !!formDataSelectors.fetchLeaderRefundProtect();
  }

  if (formDataSelectors.fetchLeaderPaymentMethod()) {
    request.payment_method = formDataSelectors.fetchLeaderPaymentMethod()!;
  }

  if (mainConditions.isSmartLinkValid()) {
    request.magic_link = smartLinkStore?.value?.token;
  }

  if (mainConditions.isCampaignLinkExists()) {
    request.campaign_link = localStorage.getItem(LOCAL_STORAGE_KEYS.campaignLinkToken);
  }

  if (mainConditions.isGiftcardEnabled.get()) {
    request.giftcard = formDataSelectors.fetchGiftcard();
  }

  if (isPayOnSiteSelectedStore.value) {
    request[AdditionalRegistrationFields.pay_onsite] = true;
  }

  return request;
}

/**
 * @description
 * Construct confirmation request for the single confirmation,push payment confirmation and team member confirmation
 */
function constructConfirmationRequest(): Request.Confirmation.Plain {
  const leader = thru(formDataSelectors.fetchRegistrationLeader(), (form) => {
    const registrationFields: Data.Backend.GroupRegistrationToSent = chain(form)
      .thru(mapperUtils.selectFormRacerByRegistrationFields(distanceSelector.registrationFields.get()))
      .thru(mapperUtils.omitCoupon)
      .thru(mapperUtils.filterFormRacerNonExistingCoupon(couponsStore, 0))
      .thru(mapperUtils.mapFormRacerToBERacer)
      .thru(mapperUtils.filterBERacerEmptyData)
      .value();

    const additionalFields: Data.Backend.RacerAdditionalFields = chain(form)
      .thru(mapperUtils.selectRacerFormAdditionalFields(fieldConditions.racerAdditionalFields()))
      .thru(mapperUtils.mapFormRacerToBERacer)
      .thru(mapperUtils.filterBERacerEmptyData)
      .value();

    const customFields = chain(formDataSelectors.fetchLeaderCustomFields())
      .thru(
        mapperUtils.rejectCustomFields({
          index: 0,
          pattern: FIELD_ID_PATTERNS.groupRegistrationCustomFields,
          fieldIds: disabledCustomFieldsStore.disabledFieldIds,
        }),
      )
      .thru(mapperUtils.mapFormCFToBECF)
      .value();

    return {
      ...additionalFields,
      ...merge(registrationFields, {
        [fieldIdUtils.staticKeys.customFieldsKey]: customFields,
      }),
    };
  });

  const coupon = couponsStore.findByFieldId(`groupRegistrations.0.coupon_code`)?.code;

  const request: Request.Confirmation.Plain = {
    member: leader,
    payment_method: formDataSelectors.fetchLeaderPaymentMethod()!,
    invite_token: confirmationStore.token!,
    distance_id: distanceSelector.id.get(),
    coupon_code: coupon,
  };

  if (distanceSelector.isRefundProtectEnabled.get()) {
    request.refund_protect = !!formDataSelectors.fetchLeaderRefundProtect();
  }

  return request;
}

function constructTransferRegistrationRequest(): Request.TransferRegistration.Plain {
  const leader = thru(formDataSelectors.fetchRegistrationLeader(), (form) => {
    const registrationFields: Data.Backend.GroupRegistrationToSent = chain(form)
      .thru(mapperUtils.selectFormRacerByRegistrationFields(distanceSelector.registrationFields.get()))
      .thru(mapperUtils.filterFormRacerNonExistingCoupon(couponsStore, 0))
      .thru(mapperUtils.mapFormRacerToBERacer)
      .thru(mapperUtils.filterBERacerEmptyData)
      .value();

    const additionalFields: Data.Backend.RacerAdditionalFields = chain(form)
      .thru(mapperUtils.selectRacerFormAdditionalFields(fieldConditions.racerAdditionalFields()))
      .thru(mapperUtils.mapFormRacerToBERacer)
      .thru(mapperUtils.filterBERacerEmptyData)
      .value();

    const customFields = chain(formDataSelectors.fetchLeaderCustomFields())
      .thru(
        mapperUtils.rejectCustomFields({
          index: 0,
          pattern: FIELD_ID_PATTERNS.groupRegistrationCustomFields,
          fieldIds: disabledCustomFieldsStore.disabledFieldIds,
        }),
      )
      .thru(mapperUtils.mapFormCFToBECF)
      .value();

    return {
      ...additionalFields,

      ...merge(registrationFields, {
        [fieldIdUtils.staticKeys.customFieldsKey]: customFields,
      }),
    };
  });

  const coupon = couponsStore.findByFieldId(`groupRegistrations.0.coupon_code`)?.code;

  const request: Request.TransferRegistration.Plain = {
    member: leader,
    payment_method: formDataSelectors.fetchLeaderPaymentMethod()!,
    transfer_token: transferStore.token!,
    distance_id: distanceSelector.id.get(),
    coupon_code: coupon,
  };

  if (distanceSelector.isRefundProtectEnabled.get()) {
    request.refund_protect = Boolean(formDataSelectors.fetchLeaderRefundProtect());
  }

  if (mainConditions.isGiftcardEnabled.get()) {
    request.giftcard = formDataSelectors.fetchGiftcard();
  }

  return request;
}

/**
 * @description
 * Construct registration requests for the group registrations edit
 */

function constructEditRegistrationsRequests(): Data.Backend.Racer[] {
  const groupMembers = formDataSelectors
    .fetchGroupRegistrationMembers()
    .filter((groupMember, index) => groupMember.is_editable || index === 0)
    .map((groupMember, index) => {
      const registrationFields: Data.Backend.GroupRegistrationToSent = chain(groupMember)
        .thru(mapperUtils.selectFormRacerByRegistrationFields(distanceSelector.registrationFields.get()))
        .thru(mapperUtils.filterFormRacerNonExistingCoupon(couponsStore, index))
        .thru(mapperUtils.mapFormRacerToBERacer)
        .thru(mapperUtils.filterBERacerEmptyData)
        .value();

      const additionalFields: Data.Backend.RacerAdditionalFields = chain(groupMember)
        .thru(mapperUtils.selectRacerFormAdditionalFields(fieldConditions.racerAdditionalFields()))
        .thru(mapperUtils.mapFormRacerToBERacer)
        .thru(mapperUtils.filterBERacerEmptyData)
        .value();

      const customFields = chain(formDataSelectors.fetchGroupRegistrationCustomFields(index))
        .thru(
          mapperUtils.rejectCustomFields({
            index: index,
            pattern: FIELD_ID_PATTERNS.groupRegistrationCustomFields,
            fieldIds: disabledCustomFieldsStore.disabledFieldIds,
          }),
        )
        .thru(mapperUtils.mapFormCFToBECF)
        .value();

      return {
        ...additionalFields,
        ...merge(registrationFields, {
          [fieldIdUtils.staticKeys.customFieldsKey]: customFields,
        }),
      };
    });

  return groupMembers;
}

export {
  constructGroupRegistrationsRequest,
  constructTeamRegistrationRequest,
  constructConfirmationRequest,
  constructTransferRegistrationRequest,
  constructEditRegistrationsRequests,
};
