import axios from 'axios';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { API_DISTANCE_WAVES, API_URL } from 'src/constants';

import { request } from 'utils';

class WavesService {
  @request({ action: shortid() })
  private _request(id: number): any {
    const url = generatePath(API_URL + API_DISTANCE_WAVES, { id });
    return axios.get(url, {
      params: {
        orderBy: 'index',
        sortedBy: 'asc',
      },
    });
  }

  async load(id: number) {
    const [isOk, response] = await this._request(id);

    if (!isOk) {
      return null;
    }

    return response.data.data;
  }
}

export { WavesService };
