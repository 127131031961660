import { initFormDataFromConfirmation } from 'modules/registration/actions/data/initFormDataFromConfirmation';
import { REGISTRATION_FORM } from 'modules/registration/constants';
import { confirmationStore, couponsStore } from 'modules/registration/stores';

import { form } from 'stores';

import { parseUrlParams } from '../../utils';

function initFormDataAfterRedirect() {
  const { raceId, distanceId } = parseUrlParams();

  if (distanceId && raceId) {
    const formDataJSON = sessionStorage.getItem(`${raceId}_${distanceId}`);
    if (formDataJSON) {
      form.merge(REGISTRATION_FORM, JSON.parse(formDataJSON));
    }

    const couponDataJSON = sessionStorage.getItem(`${raceId}_${distanceId}_coupon`);
    if (couponDataJSON) {
      couponsStore.setValue(JSON.parse(couponDataJSON));
    }

    const confirmationDataJSON = sessionStorage.getItem(`${raceId}_${distanceId}_confirmation`);
    if (confirmationDataJSON) {
      const confirmationData = JSON.parse(confirmationDataJSON);
      confirmationStore.setTokenData(confirmationData.token, confirmationData.tokenData);
      initFormDataFromConfirmation();
    }
  }
}

export { initFormDataAfterRedirect };
