import { computed, toJS } from 'mobx';
import shortid from 'shortid';

import { getAllGiftcardFields } from './_getAllGiftcardFields';
import { giftcardErrors } from './giftcardErrors';

/**
 * @description
 * Generate observable computed variable
 * that will trigger change in case
 * if
 *  - new errors added
 *  - or order of the changed field has been updated
 *
 * return value - string, 'AS2KJDH' (shortid)
 */
function giftcardErrorReactionTrigger() {
  const giftcardChanges = computed(() => {
    const fields = getAllGiftcardFields();
    const errors = giftcardErrors.get();
    return { fields, errors };
  });

  return computed(() => {
    giftcardChanges.get();
    return shortid();
  }).get();
}

export { giftcardErrorReactionTrigger };
