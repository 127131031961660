import { isEqual } from 'lodash';
import { reaction, transaction } from 'mobx';

import { snackActions } from '../actions';

import { disposers, isOfflineStore } from '../stores';

import { mainConditions } from '../selectors/mainConditions';

/**
 * @description
 * Observe conditions to display registration snacks that blocks registration
 */
function observeConditionsForPermanentSnacks() {
  const disposer = reaction(
    () => [
      mainConditions.isAlreadyRegisteredAndNotInvited.get(),
      mainConditions.isRegistrationOutdated.get(),
      mainConditions.isRegistrationClosed.get(),
      mainConditions.isDistanceSoldOut.get(),
      mainConditions.isInvalidInviteToken.get(),
      mainConditions.isRegistrationOutdatedForPushPayment.get(),
      isOfflineStore.value,
    ],
    () => {
      transaction(() => {
        if (mainConditions.isAlreadyRegisteredAndNotInvited.get()) {
          snackActions.blockedRegistration.alreadyRegisteredSnack.show();
        } else {
          snackActions.blockedRegistration.alreadyRegisteredSnack.clean();
        }

        if (
          (mainConditions.isRegistrationOutdated.get() && !mainConditions.isRegistrationClosed.get()) ||
          mainConditions.isRegistrationOutdatedForPushPayment.get()
        ) {
          snackActions.blockedRegistration.registrationOutdatedSnack.show();
        } else {
          snackActions.blockedRegistration.registrationOutdatedSnack.clean();
        }

        if (mainConditions.isRegistrationClosed.get()) {
          snackActions.blockedRegistration.registrationClosedSnack.show();
        } else {
          snackActions.blockedRegistration.registrationClosedSnack.clean();
        }

        if (mainConditions.isDistanceSoldOut.get()) {
          snackActions.blockedRegistration.distanceSoldOutSnack.show();
        } else {
          snackActions.blockedRegistration.distanceSoldOutSnack.clean();
        }

        if (mainConditions.isInvalidInviteToken.get()) {
          snackActions.blockedRegistration.invalidInviteTokenSnack.show();
        } else {
          snackActions.blockedRegistration.invalidInviteTokenSnack.clean();
        }

        if (isOfflineStore.value) {
          snackActions.offlineSnack.show();
        } else {
          snackActions.offlineSnack.clean();
        }
      });
    },
    { equals: isEqual, fireImmediately: true },
  );

  disposers.register(() => {
    disposer();
  });
}

export { observeConditionsForPermanentSnacks };
