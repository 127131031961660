import { transaction } from 'mobx';

import { preloaders } from '../utils';

import { couponsStore, currentPaymentStore, isPaymentFrameOpened, paymentTrackerStore } from '../stores';

import { dataActions } from './data';

function onDistanceChange() {
  transaction(() => {
    dataActions.initForm();
    currentPaymentStore.endPayment();
    isPaymentFrameOpened.off();

    paymentTrackerStore.clean();
    couponsStore.clear();
  });

  preloaders.initializeDistance.disable();
  preloaders.initializeBaseData.disable();
}

export { onDistanceChange };
