import axios from 'axios';
import shortid from 'shortid';

import { API_URL, API_USER_CHECK_EMAIL_URL } from 'src/constants';

import { request, action } from 'utils';

class EmailExistsService {
  @request({ action: shortid() })
  request(email: string): any {
    const url = API_URL + API_USER_CHECK_EMAIL_URL;
    return axios.get(url, { params: { email } });
  }

  @action({ action: shortid() })
  async load(email: string): Promise<boolean> {
    const [, response] = await this.request(email);
    return response.data.exists;
  }
}

export { EmailExistsService };
