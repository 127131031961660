import moment from 'moment';
import * as React from 'react';

import { CountdownTimer } from 'components/countdownTimer';

import { snackStore } from 'stores';

import { SNACK_SCOPES } from '../../../constants';

import { currentPaymentStore } from '../../../stores';

let snackId: void | string | null;

// Show or hide pending payment snack
function show(createdAt: string) {
  const expDate = generatePaymentExpirationDate(createdAt);

  const snackParams = {
    i18key: 'registration.payment.pending',
    payload: {
      body: (
        <CountdownTimer
          className='d-inline-block'
          expireDate={expDate}
          onExpireCallback={() => {
            /*TODO*/
          }}
        />
      ),
    },
  };

  if (!!snackId) {
    return snackStore.updateValue(snackId, snackParams);
  }

  snackId = snackStore.warn(snackParams, { scope: SNACK_SCOPES.payment });
}

function clean() {
  snackId && snackStore.removeValue(snackId);
  snackId = null;
}

function generatePaymentExpirationDate(createdAt: string) {
  const mCreatedAt = moment.utc(createdAt);
  const expTtlMinutes = currentPaymentStore.registrationTTL || 0;
  const expTtlSeconds = 60 - mCreatedAt.seconds();

  const expDate = mCreatedAt.clone();
  expDate.add(expTtlMinutes, 'minutes');
  expDate.add(expTtlSeconds, 'seconds');

  return expDate;
}

const pendingSnack = {
  show,
  clean,
};

export { pendingSnack };
