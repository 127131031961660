import { isArray } from 'lodash';

import { form, progressStore } from 'stores';

import { GIFTCARD_LOADING, REGISTRATION_FORM } from '../../constants';

import { giftcardService } from '../../services';

import { giftcardStore } from '../../stores';

import { distanceSelector } from '../../selectors/mappedData';

/**
 * @description
 * Check is current giftcard code is valid and init it
 * if valid mark it as invalid
 * if not valid load giftcard model and set it to the store
 */
function initGiftcard(fieldId: string) {
  return async () => {
    const code = form.fetch<string | nil>(REGISTRATION_FORM, fieldId);
    const distanceId = distanceSelector.id.get();

    if (!distanceId || !code) {
      return null;
    }

    progressStore.log(GIFTCARD_LOADING, 'progress');
    const giftcardData = await giftcardService.load(distanceId, code);
    progressStore.log(GIFTCARD_LOADING, 'completed');

    if (isArray(giftcardData)) {
      giftcardStore.initInvalid(fieldId, giftcardData);
    } else {
      giftcardStore.init(fieldId, giftcardData);
    }
  };
}

export { initGiftcard };
