import registrationStatus from 'stores/registrationStatus';

import { currentPaymentStore, isPaymentFrameOpened, serviceFeeStore } from '../stores';

import { Data } from '../types';
import { snackActions } from './snacks';

/**
 * @description
 * everthing that is necessary to complete a payment
 */
function processPayment(
  token: string,
  operations: Data.Backend.OperationFromBE[],
  registration_uuid: string,
  paymentUuid: string,
  serviceFee: number,
  payment_method_order: string[],
) {
  registrationStatus.isAttemptedPayment = true;
  serviceFeeStore.setServiceFee(serviceFee);
  currentPaymentStore.startPayment(token, operations, payment_method_order, registration_uuid, paymentUuid);
  isPaymentFrameOpened.on();

  snackActions.payment.cancelSnack.clean();
  snackActions.payment.cannotCancelSnack.clean();
}

export { processPayment };
