import { cloneDeep } from 'lodash';
import { AdditionalRegistrationFields, REGISTRATION_FORM } from 'modules/registration/constants';
import { mainConditions } from 'modules/registration/selectors/mainConditions';
import { selectOptions } from 'modules/registration/selectors/selectOptions';
import { smartLinkStore } from 'modules/registration/stores';
import { fieldIdUtils } from 'modules/registration/utils';

import { form } from 'stores';

export const initFormDataFromSmartLink = () => {
  if (!mainConditions.isSmartLinkRegistration()) {
    return;
  }
  if (smartLinkStore.value?.wave_id) {
    const waves = selectOptions.waves.get();
    const pickedWave = waves.find((option: NewSelectItemType) => option.value === smartLinkStore.value?.wave_id);

    if (mainConditions.isPageRegistrationTeamWithCustomFields.get() || mainConditions.isPageRegistrationTeamWithoutCustomFields.get()) {
      form.onChange(REGISTRATION_FORM, AdditionalRegistrationFields.wave_id, pickedWave);
    }

    if (mainConditions.isPageRegistrationSingleWithCustomFields.get() || mainConditions.isPageRegistrationSingleWithoutCustomFields.get()) {
      form.onChange(REGISTRATION_FORM, `${fieldIdUtils.groupRegistrationWithIndex(0)}.${AdditionalRegistrationFields.wave_id}`, pickedWave);
    }
  }
};
