import axios from 'axios';
import { CHECK_REGISTRATION_STATUS, GET_REGISTRATION_RACERS } from 'modules/registration/constants';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { API_URL, API_REGISTRATION, API_REGISTRATION_STATUS, API_REGISTRATION_RACERS } from 'src/constants';

import { request, action } from 'utils';

import { Request, Response } from '../types';

class RegistrationService {
  @request({ action: shortid() })
  _submitRequest(raceId: number, registrationToken: string | nil, data: Request.Registration.SingleGroup | Request.Registration.Team): any {
    const url = generatePath(API_URL + API_REGISTRATION, { raceId });

    const headers = {};

    if (registrationToken) {
      headers['registration-token'] = registrationToken;
    }

    return axios({
      method: 'POST',
      url,
      headers,
      data,
    });
  }

  @request({ action: CHECK_REGISTRATION_STATUS })
  _checkRegistrationStatusRequest(uuid: string): any {
    const url = generatePath(API_URL + API_REGISTRATION_STATUS, { uuid });

    return axios({ method: 'GET', url });
  }

  @request({ action: GET_REGISTRATION_RACERS })
  _checkRegistrationRacersRequest(registrationId: number): any {
    const url = generatePath(API_URL + API_REGISTRATION_RACERS, { registrationId });

    return axios({ method: 'GET', url });
  }

  @action({ action: shortid() })
  /**
   * @description
   * submit group registrations
   */
  async submitSingleGroup(
    raceId: number,
    registrationToken: string | nil,
    data: Request.Registration.SingleGroup,
  ): Promise<{
    data: null | Response.Registration.SingleGroup;
    isOk: boolean;
    errors: RawError;
    message: string | null;
  }> {
    const [isOk, response] = await this._submitRequest(raceId, registrationToken, data);
    return {
      isOk,
      data: response.data || null,
      errors: response.data.errors || null,
      message: response.statusText,
    };
  }

  @action({ action: shortid() })
  /**
   * @description
   * submit group registrations
   */
  async submitTeam(
    raceId: number,
    registrationToken: string | nil,
    data: Request.Registration.Team,
  ): Promise<{
    data: null | Response.Registration.SingleGroup;
    isOk: boolean;
    errors: RawError;
    message: string | null;
  }> {
    const [isOk, response] = await this._submitRequest(raceId, registrationToken, data);

    return {
      isOk,
      data: response.data || null,
      errors: response.data.errors || null,
      message: response.data.message,
    };
  }

  @action({ action: CHECK_REGISTRATION_STATUS })
  /**
   * @description
   * check the registration status by registration uuid
   */
  async checkRegistrationStatus(uuid: string): Promise<{
    data: { data: null | Response.Registration.Status };
    isOk: boolean;
    errors: RawError;
    message: string | null;
  }> {
    const [isOk, response] = await this._checkRegistrationStatusRequest(uuid);

    return {
      isOk,
      data: response.data || null,
      errors: response.data.errors || null,
      message: response.data.message,
    };
  }

  @action({ action: GET_REGISTRATION_RACERS })
  /**
   * @description
   * get the registration racers by registration id
   */
  async getRegistrationRacers(registrationId: number): Promise<{
    data: { data: null | Response.Registration.Status };
    isOk: boolean;
    errors: RawError;
    message: string | null;
  }> {
    const [isOk, response] = await this._checkRegistrationRacersRequest(registrationId);

    return {
      isOk,
      data: response.data || null,
      errors: response.data.errors || null,
      message: response.data.message,
    };
  }
}

export { RegistrationService };
