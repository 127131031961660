import axios from 'axios';
import { flatten, compact } from 'lodash';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { API_URL, API_COUPON_API } from 'src/constants';

import { request, action } from 'utils';

import { LCoupon } from '../types';

class CouponCodeService {
  @request({ action: shortid() })
  request(distanceId: number, code: string): any {
    const params = {
      code,
    };

    const url = generatePath(API_URL + API_COUPON_API, { distanceId });

    return axios.get(url, { params });
  }

  @action({ action: shortid() })
  async load(distanceId: number, code: string): Promise<LCoupon | string[]> {
    const [isOk, response] = await this.request(distanceId, code);

    if (!isOk) {
      return compact(flatten([response.data.errors?.coupon_code, response.data.message]));
    }

    return response.data.data;
  }
}

export { CouponCodeService };
