import { chain, isEmpty } from 'lodash';

import { t, objectsDifference, fireToast } from 'utils';

import { userService, authService } from 'services';

import { sessionStore } from 'stores';

import { mapperUtils } from '../../utils';
import { toastUtils } from '../../utils/toasts';

import { updateProfileService } from '../../services';

import { formDataSelectors } from '../../selectors/formDataSelectors';
import { mainConditions } from '../../selectors/mainConditions';
import { distanceSelector } from '../../selectors/mappedData';
import { errorsActions } from '../errors';

/**
 * @description
 * PATCH update profile
 *
 * @returns
 * true if there are nothing to update or successfull update, false if validation error
 *
 */
async function updateProfile({ skipSuccessMessage } = { skipSuccessMessage: false }): Promise<boolean> {
  const userToBeUpdated = chain(formDataSelectors.fetchRegistrationLeader())
    .thru(mapperUtils.selectFormRacerByRegistrationFields(distanceSelector.registrationFields.get()))
    .thru(mapperUtils.omitCoupon)
    .thru(mapperUtils.mapFormRacerToBERacer)
    .thru(mapperUtils.filterBERacerEmptyData)
    .value();

  const currentUserFields = chain(sessionStore.getUserData())
    .thru(mapperUtils.selectBERacerByRegistrationFields(distanceSelector.registrationFields.get()))
    .thru(mapperUtils.filterBERacerEmptyData)
    .value();

  const diff = objectsDifference(userToBeUpdated, currentUserFields);

  /**
   * There are nothing to update, skipping update
   */
  if (isEmpty(diff)) {
    return true;
  }

  const { isOk, errors } = await updateProfileService.update(userToBeUpdated);

  if (!isOk) {
    errorsActions.catchProfileUpdateErrors(errors);
    toastUtils.generalValidation();
    return false;
  }

  !skipSuccessMessage && fireToast(t.staticAsString('toast.profileUpdated'), 'success', '', 'top-right');

  userService.loadUserProfile();
  return true;
}

/**
 * @description
 *
 * POST create a profile
 * log in user
 *
 * true if or successfull submit
 *
 */
async function createProfile(): Promise<boolean> {
  const userToBeRegistered = formDataSelectors.fetchUserForRegistration();

  const request: SignupType = {
    ...userToBeRegistered,
    termsAgreement: true,
    isAuth: false,
    is_organizer: false,
  }!;

  const isRegistered = await authService.registration(request);

  if (!isRegistered) {
    errorsActions.catchProfileRegistrationErrors();
    toastUtils.generalValidation();
    return false;
  }

  return true;
}

/**
 * @description
 *
 * if not no profile distance, then
 * create profile if not exists
 * and
 * update profile
 */
async function executeProfileActions(): Promise<boolean> {
  if (mainConditions.isNoProfile.get()) {
    return true;
  }

  if (mainConditions.isLogged.get()) {
    if (!(await updateProfile({ skipSuccessMessage: false }))) {
      return false;
    }
  } else {
    if (!(await createProfile())) {
      return false;
    }

    if (!(await updateProfile({ skipSuccessMessage: true }))) {
      return false;
    }
  }

  return true;
}

export { updateProfile, createProfile, executeProfileActions };
