import { isEqual } from 'lodash';
import { reaction } from 'mobx';

import { DistanceRegistrationModel } from 'models';

import { paymentCompleteActions } from '../actions/paymentCompleteActions';

import { currentPaymentStore, disposers, isPaymentFrameOpened, paymentTrackerStore } from '../stores';

/**
 * @description
 * observer for handling all kind of payment mutations
 * canceled, aborted, pending, ready
 *
 * based on state mutation, side effects will be executed
 */
function observeCurrentPayment() {
  let prevPayment: DistanceRegistrationModel | nil;

  const disposer = reaction(
    () => {
      const token = currentPaymentStore.token;
      const payment = paymentTrackerStore.findPaymentByToken(token);
      const isPaymentFrame = isPaymentFrameOpened.value;

      return { nextPayment: payment, isPaymentFrame };
    },
    (data) => {
      const { nextPayment, isPaymentFrame } = data;

      if (prevPayment?.value.token !== nextPayment?.value.token) {
        prevPayment = null;
      }

      paymentController(prevPayment, nextPayment, isPaymentFrame);

      prevPayment = nextPayment;
    },
    { equals: isEqual },
  );

  disposers.register(disposer);
}

function paymentController(
  prevPayment: DistanceRegistrationModel | nil,
  nextPayment: DistanceRegistrationModel | nil,
  isPaymentFrame: boolean,
) {
  if (!nextPayment) {
    return;
  }

  if (nextPayment.isPending()) {
    paymentCompleteActions.pending();
    return;
  }

  const isPrevPaymentPending = !prevPayment || prevPayment.isPending();
  const isPrevPaymentFailed = !prevPayment || prevPayment.isFailed();
  const isPrevPaymentReady = !prevPayment || prevPayment.isReady();

  // pending -> ready
  if (isPrevPaymentPending && nextPayment.isReady()) {
    paymentCompleteActions.success();
    return;
  }

  // failed -> ready
  if (isPrevPaymentFailed && nextPayment.isReady()) {
    paymentCompleteActions.success();
    return;
  }

  /* ready -> ready
   * should not be the case, should be handeled via:
   * pending -> ready or failed -> ready
   * but still
   */
  if (isPrevPaymentReady && nextPayment.isReady()) {
    paymentCompleteActions.success();
    return;
  }

  // pending -> aborted
  if (isPrevPaymentPending && nextPayment.isAborted()) {
    paymentCompleteActions.cancel();
    return;
  }

  // failed -> aborted
  if (isPrevPaymentFailed && nextPayment.isAborted()) {
    paymentCompleteActions.cancel();
    return;
  }

  /* aborted -> aborted
   * should not be the case, should be handeled via:
   * pending -> aborted or failed -> aborted
   * but still
   */
  if (isPrevPaymentFailed && nextPayment.isAborted()) {
    paymentCompleteActions.cancel();
    return;
  }

  /**
   * payment can be paid after it's failed
   * if used still pays payment it can be changed from Failed to Ready or Aborted
   * so, closing payment with failed status should be processed only when payment popup is closed and user cannot do anything else
   */
  if (!isPaymentFrame && nextPayment.isFailed()) {
    paymentCompleteActions.cancel();
    return;
  }
}

export { observeCurrentPayment };

// We dont't have any payments so it's doen't work now (with stripe)
