import { reaction } from 'mobx';
import { mainConditions } from 'modules/registration/selectors/mainConditions';

import { dataActions } from '../actions';

import { disposers } from '../stores';

import { distanceSelector } from '../selectors/mappedData';

/**
 * @description
 * Observe the type of payment, if it was based on a redirect -
 * merging the saved data to register form
 */
function stripeRedirectObserver() {
  const disposer = reaction(
    () => ({
      id: distanceSelector.id.get(),
      isStripeRedirect: mainConditions.isStripeRedirect(),
    }),
    ({ id, isStripeRedirect }) => {
      if (id && isStripeRedirect) {
        dataActions.initFormDataAfterRedirect();
      }
    },
    { fireImmediately: true },
  );

  disposers.register(disposer);
}

export { stripeRedirectObserver };
