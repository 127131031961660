import { reaction } from 'mobx';

import { loaderActions } from '../actions/loader';

import { disposers, emailExistsStore } from '../stores';

import { formDataSelectors } from '../selectors/formDataSelectors';
import { mainConditions } from '../selectors/mainConditions';
import { registrationLeaderEmailExistsErrorUtil } from '../validations';

/**
 * @description
 * Observe registration leader email
 * and if it has been changed, check if it does not exists on BE
 */
function observeRegistrationLeaderEmail() {
  const disposer = reaction(formDataSelectors.fetchLeaderEmail, () => {
    emailExistsStore.off();
    registrationLeaderEmailExistsErrorUtil.cleanError();

    if (mainConditions.isNoProfile.get()) {
      return;
    }

    if (mainConditions.isLogged.get()) {
      return;
    }

    loaderActions.debouncedCheckisEmailExists();
  });

  disposers.register(disposer);
}

export { observeRegistrationLeaderEmail };
