import { keys } from 'lodash';

import { fieldIdUtils } from '../../utils';

import { _addErrors } from './_addErrors';

/**
 * @description
 * Remap and add profile update errors
 * almost all registration fields
 */
function catchProfileUpdateErrors(errors: RawError | nil) {
  if (!errors) {
    return;
  }

  const errorKeys = keys(errors);

  const mappedErrors = errorKeys.reduce((processedErrors, errorKey) => {
    const newErrorKey = `${fieldIdUtils.registrationLeader()}.${errorKey}`;

    processedErrors[newErrorKey] = errors[errorKey];

    return processedErrors;
  }, {});

  _addErrors(mappedErrors);
}

export { catchProfileUpdateErrors };
