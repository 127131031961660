import { snackStore } from 'stores';

import { SNACK_SCOPES } from '../../../constants';

let snackId: string;

function show() {
  const snackParams = {
    i18key: 'registration.messages.alreadyRegisteredToDistance',
  };

  clean();
  snackId = snackStore.warn(snackParams, { scope: SNACK_SCOPES.blockedRegistration });
}

function clean() {
  snackStore.removeAll((snack) => snack.options?.scope === SNACK_SCOPES.blockedRegistration);
  snackId && snackStore.removeValue(snackId);
}

const alreadyRegisteredSnack = {
  show,
  clean,
};

export { alreadyRegisteredSnack };
